import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import AlertMessage from '../components/AlertMessage/AlertMessage';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { updateTheme } from '../utils/updateTheme.js';
import { checkValidPassword } from '../utils/checkValidPassword';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faCircleInfo } from '@fortawesome/free-solid-svg-icons';

import './PageStyles.css';

// FOR CHECKING PASSWORD VALIDATIONS, HANDLE VALIDITY BY ID, NOT INDEX
// IN THE LOGIN COMPONENT - NEED TO CHECK HANDLING FOR WRONG PASSWORD SENT - NO ERROR MESSAGE WAS SEEN

export default function Settings({ userData, setUserData, axiosInstance }) {
    const [validated, setValidated] = useState(false);
    const [displayName, setDisplayName] = useState(userData.displayName);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('danger');
    const [alertMessage, setAlertMessage] = useState('');

    const passwordDescription = 'Passwords must be 8-32 characters and include at least 1 number, 1 upper case, and 1 special character.'

    function handleUpdateName(e) {
        e.preventDefault();

        setValidated(true);
        if (e.currentTarget.checkValidity() === true) {
            sendChangeName();
        } 
    }

    function handleUpdateOldPassword(e) {
        if (checkValidPassword(e.currentTarget.value)) {
            e.currentTarget.setCustomValidity('');
            setShowAlert(false);
        }
        setOldPassword(e.currentTarget.value);
    }

    function handleUpdateNewPassword(e) {
        if (checkValidPassword(e.currentTarget.value)) {
            e.currentTarget.setCustomValidity('');
            setShowAlert(false);
        }
        setNewPassword(e.currentTarget.value);
    }

    function handleUpdateConfirmPassword(e) {
        if (e.currentTarget.value === newPassword) {
            e.currentTarget.setCustomValidity('');
            setShowAlert(false);
        } else {
            e.currentTarget.setCustomValidity('The new password and confirmation are not the same.  Please try again.');
        }

        setConfirmPassword(e.currentTarget.value);
    }

    function handleSubmitPasswords(e) {
        e.preventDefault();

        if (newPassword != confirmPassword) {
            const errorMessage = 'The new password and confirmation are not the same.  Please try again.';
            e.currentTarget[2].setCustomValidity(errorMessage);
            setAlertType('danger');
            setAlertMessage(errorMessage);
            setShowAlert(true);
        } else if (newPassword === oldPassword) {
            const errorMessage = 'The new password cannot be the same as your current password.';
            e.currentTarget[1].setCustomValidity(errorMessage);
            setAlertType('danger');
            setAlertMessage(errorMessage);
            setShowAlert(true);
        } else if (!checkValidPassword(newPassword)) {
            const errorMessage = 'The new password is not valid. ' + passwordDescription;
            e.currentTarget[2].setCustomValidity(errorMessage);
            setAlertType('danger');
            setAlertMessage(errorMessage);
            setShowAlert(true);
        }
        setValidated(true);

        if (e.currentTarget.checkValidity() === true) {
           sendChangePassword();
        } 
    }

    async function sendChangeTheme(newTheme) {
        const updateThemeRequestBody = {
            theme: newTheme
        }
        let response;

        try {
            response = await axiosInstance.put(`users/${userData.id}/update/theme`, updateThemeRequestBody);
            if (response && response.status === 200) {
                updateTheme(newTheme)
                setUserData({ ...userData, theme: newTheme});
                setAlertType('success');
                setAlertMessage(`Theme changed to ${newTheme} mode.`);
            }
        } catch (err) {
            console.error(err);
            setAlertType('danger');
            setAlertMessage('Unable to update theme due to server error:' + err);
        } finally {
            setShowAlert(true);
        }
    }

    async function sendChangeExample(newVal) {
        const updateExampleRequestBody = {
            useExample: newVal
        }
        let response;

        try {
            response = await axiosInstance.put(`users/${userData.id}/update/example`, updateExampleRequestBody);
            if (response && response.status === 200) {
                setUserData({ ...userData, useExample: newVal});
                setAlertType('success');
                setAlertMessage(`Example budget will be ${newVal ? 'shown' : 'hidden'}.`);
            }
        } catch (err) {
            console.error(err);
            setAlertType('danger');
            setAlertMessage('Unable to update example budget display due to server error:' + err);
        } finally {
            setShowAlert(true);
        }
    }

    async function sendChangeName() {
        const updateNameRequestBody = {
            displayName: displayName
        }
        let response;

        try {
            response = await axiosInstance.put(`users/${userData.id}/update/name`, updateNameRequestBody);
            if (response && response.status === 200) {
                setUserData({ ...userData, displayName: displayName});
                setAlertType('success');
                setAlertMessage(`Display name changed to ${displayName}.`);
            }
        } catch (err) {
            console.error(err);
            setAlertType('danger');
            setAlertMessage('Unable to update display naem due to server error:' + err);
        } finally {
            setShowAlert(true);
        }
    }

    async function sendChangePassword() {
        const sendLoginRequestBody = { 
            oldPassword: oldPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword
        };

        let response;
        let errorMessage;

        try {
            response = await axiosInstance.put(`users/${userData.id}/update/password`, sendLoginRequestBody);
            if (response && response.status === 200) {
                setUserData({ ...userData, id: response.data.id, displayName: response.data.display_name });
            } else if (response.response.status === 400) {
                errorMessage = 'The passwords sent to the server do not meet the criteria.' + passwordDescription;
            } else if (response.response.status === 403) {
                errorMessage = 'The current password sent does not match our records';
            } else if (response.response.status === 404) {
                errorMessage = 'No records were found for your account. Please try again later.';
            } else {
                errorMessage = 'Unknown Error';
            }
        } catch (err) {
            console.error(err);
            errorMessage = `The server encountered a problem.  Please try again later.  (${err})`;
        } finally {
            if (errorMessage) {
                setAlertType('danger');
                setAlertMessage(errorMessage);
            } else {
                setAlertType('success');
                setAlertMessage('Your password has been updated.');
            }
            setShowAlert(true);
        }
    }

    return (
        <Container fluid className='page-container'>
            <Row>
                <AlertMessage 
                    show={showAlert}
                    setShow={setShowAlert}
                    variant={alertType}
                    message={alertMessage}
                    autoRemove={alertType === 'success' ? 5000 : false}
                />
            </Row>

            <Row className='h-25 mt-4 align-items-center'>
                <Col xs={11} sm={8} xl={6} className='mx-auto h-100 text-center'>
                    <div style={{height: '100%'}}>
                        <Card className='settings-card'>
                            <Card.Header className='settings-header'>
                                <Card.Title>Show Example Budget</Card.Title>
                            </Card.Header>
                            <Card.Body className='d-flex align-items-center'>
                                <ToggleButtonGroup type="radio" name="example-toggle-btns" className='w-100' value={userData.useExample} onChange={(newVal) => sendChangeExample(newVal)}>
                                    <ToggleButton id="show-toggle" type='radio' variant='outline-primary' className='mx-3 w-25' value={true}>
                                        Show
                                    </ToggleButton>
                                    <ToggleButton id="hide-toggle" type='radio' variant='outline-secondary' className='mx-3 w-25' value={false}>
                                        Hide
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            </Row>

            <Row className='h-25 mt-4 align-items-center'>
                <Col xs={11} sm={8} xl={6} className='mx-auto h-100 text-center'>
                    <div style={{height: '100%'}}>
                        <Card className='settings-card'>
                            <Card.Header className='settings-header'>
                                <Card.Title>Change Theme</Card.Title>
                            </Card.Header>
                            <Card.Body className='d-flex align-items-center'>
                                <ToggleButtonGroup type="radio" name="theme-toggle-btns" className='w-100' value={userData.theme} onChange={(newVal) => sendChangeTheme(newVal)}>
                                    <ToggleButton id="light-toggle" type='radio' variant='outline-primary' className='mx-3 w-25' value={'light'}>
                                        Light
                                    </ToggleButton>
                                    <ToggleButton id="calm-toggle" type='radio' variant='outline-secondary' className='mx-3 w-25' value={'calm'}>
                                        Calm
                                    </ToggleButton>
                                    <ToggleButton id="dark-toggle" type='radio' variant='outline-dark' className='mx-3 w-25' value={'dark'}>
                                        Dark
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            </Row>

            <Row className='h-25 mt-4 align-items-center'>
                <Col xs={11} sm={8} xl={6} className='mx-auto h-100 text-center'>
                    <div style={{height: '100%'}}>
                        <Card className='settings-card'>
                            <Card.Header className='settings-header'>
                                <Card.Title>Change Display Name</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form
                                    noValidate 
                                    onSubmit={handleUpdateName}
                                    validated={validated} 
                                    className='h-100 text-center'
                                >
                                    <Row className='h-100 align-items-center'>
                                        <Col xs={12} sm={8} className='m-auto text-start'>
                                            <Form.Control 
                                                required
                                                type='text'
                                                value={displayName}
                                                placeholder='New Display Name'
                                                maxLength={255}
                                                onChange={(e) => setDisplayName(e.currentTarget.value)}
                                            />
                                        </Col>

                                        <Col xs={12} sm={4} className='m-auto'>
                                            <Button type='submit' variant='outline-success' className='w-100' disabled={displayName?.trim() === userData.displayName || !displayName.trim()}>
                                                Save <FontAwesomeIcon icon={faFloppyDisk} />
                                            </Button>
                                        </Col>

                                    </Row> 
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            </Row>

            <Row className='h-75 mt-4 align-items-center'>
                <Col xs={11} sm={8} xl={6} className='mx-auto h-100 text-center'>
                    <div style={{height: '100%'}}>
                        <Card className='settings-card'>
                            <Card.Header className='settings-header'>
                                <Card.Title>
                                    Change Password {' '}
                                    <OverlayTrigger 
                                        placement='right' 
                                        trigger={['hover', 'click']} 
                                        overlay={<Tooltip>{passwordDescription}</Tooltip>}
                                    >
                                        <FontAwesomeIcon icon={faCircleInfo} className='pasword-info-icon' />
                                    </OverlayTrigger>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form
                                    noValidate 
                                    onSubmit={handleSubmitPasswords}
                                    validated={validated} 
                                    className='h-100 text-center'
                                >
                                    <Row className='h-100 align-items-center'>
                                        <Col xs={12} sm={8} className='m-auto text-start'>
                                            <Form.Label>Current</Form.Label>
                                            <Form.Control 
                                                required
                                                type='password'
                                                value={oldPassword}
                                                placeholder='Current Password'
                                                maxLength={255}
                                                minLength={8}
                                                onChange={(e) => handleUpdateOldPassword(e)}
                                            />

                                            <Form.Label className='mt-4'>New</Form.Label>
                                            <Form.Control 
                                                required
                                                type='password'
                                                value={newPassword}
                                                placeholder='New Password'
                                                maxLength={255}
                                                minLength={8}
                                                onChange={(e) => handleUpdateNewPassword(e)}
                                            />

                                            <Form.Control 
                                                required
                                                type='password'
                                                value={confirmPassword}
                                                placeholder='Confirm New Password'
                                                maxLength={255}
                                                minLength={8}
                                                onChange={(e) => handleUpdateConfirmPassword(e)}
                                            />
                                        </Col>

                                        <Col xs={12} sm={8} className='m-auto my-4'>
                                            <Button type='submit' variant='outline-success' className='w-100' disabled={!oldPassword || !newPassword || !confirmPassword}>
                                                Save <FontAwesomeIcon icon={faFloppyDisk} />
                                            </Button>
                                        </Col>

                                    </Row> 
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
import React from 'react';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';
import Button from 'react-bootstrap/esm/Button';
import Progress from '../Progress/Progress.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines, faSquare, faPencil, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { currencyFormatter } from '../../utils/currencyFormatter.js';

import './CategoryStyles.css';

export default function CategoryDisplay({ id, name, color, limit, expenses, anyCategoryIsNew, changeCategoryColor, setIsEditing, askDeleteCategory, localeInfo, dragHandleProps }) {

    const expenseTotal = expenses.reduce((a, b) => a + b.amount, 0);
    const expenseTotalDisplay = currencyFormatter(localeInfo.languageCode, localeInfo.currencyCode).format(expenseTotal);
    const limitDisplay = limit ?' / ' + currencyFormatter(localeInfo.languageCode, localeInfo.currencyCode).format(limit) : ' / no limit';
    
    function handleEdit(e) {
        e.stopPropagation();
        setIsEditing(true);
    }

    function handleAskDelete(e) {
        e.stopPropagation();
        askDeleteCategory(id, name, expenseTotalDisplay);
    }

    return (
        <>
            <Col xs={2} sm={1} className='category-drag-icon' {...dragHandleProps} onClick={(e) => e.stopPropagation()}>
                <FontAwesomeIcon icon={faGripLines} size='lg' inverse={anyCategoryIsNew} />
            </Col>
            
            <Col xs={6} sm={4} md={3} className='category-title' title={name}>
                <FontAwesomeIcon icon={faSquare} className='category-color-icon' fontSize={24} color={color} onClick={(e) => {
                    e.stopPropagation();
                    changeCategoryColor(id)}} />
                <span>&nbsp;</span>
                <strong>{name}</strong>
            </Col>

            {/* Configuration of Info For XS-size screens */}


            {/* Configuration of Info For Small-size screens */}
            <Col xs={4} md={5} className='d-none d-sm-inline-block d-md-none'>
                <strong>{expenseTotalDisplay}</strong>{limitDisplay}
                <Progress limit={limit} currVal={expenseTotal} type='category' />
            </Col>

            {/* Configuration of Info For Medium-size and up screens */}
            <Col xs={4} sm={3} className='d-inline-block d-sm-none d-md-inline-block text-start category-total-col'>
                <strong>{expenseTotalDisplay}</strong><span className='d-none d-sm-inline-block'>{limitDisplay}</span>
            </Col>

            <Col xs={3} className='d-none d-md-inline-block' >
                <Progress limit={limit} currVal={expenseTotal} type='category' />
            </Col>

            <Col xs={4} sm={3} md={2} className="p-0 category-btn-col">
                <ButtonGroup size='sm' className='category-btn-group' onClick={(e) => e.stopPropagation()}>
                    { !(expenses.length && expenses[expenses.length-1].is_new) && 
                        <>
                            <Button 
                                variant='outline-primary' 
                                className='category-btn' 
                                onClick={(e) => handleEdit(e)}
                                ><FontAwesomeIcon icon={faPencil} />
                            </Button>

                            <Button 
                                variant='outline-danger' 
                                className='category-btn' 
                                onClick={(e) => handleAskDelete(e)}
                                ><FontAwesomeIcon icon={faTrashCan} />
                            </Button>
                        </>
                    }
                </ButtonGroup>
            </Col>
        </>
    );
}
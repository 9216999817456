import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShuffle } from '@fortawesome/free-solid-svg-icons';

import './ExpenseStyles.css';

export default function ExpenseHeader({ limit }) {
    return (
        <Row className='expense-header'>
            <Col xs={1}><FontAwesomeIcon icon={faShuffle}  /></Col>
            <Col xs={5} sm={4}><strong>Description</strong></Col>
            <Col xs={4} sm={3} className='p-0'><strong>Amount</strong></Col>
            <Col xs={2} className='d-none d-sm-inline-block'><strong>{limit ? 'Lmt.%' : 'Amt.%'}</strong></Col>
            <Col xs={2} className='p-0'><strong>Edit</strong></Col>
        </Row>
    );
}
import React from 'react';
import Alert from 'react-bootstrap/Alert';

export default function AlertMessage({show, setShow, variant='danger', message, autoRemove=false}) {

    if (autoRemove && !isNaN(autoRemove)) {
        setTimeout(() => {
            setShow(false);
        }, autoRemove);
    }

    return (
        <Alert dismissible transition show={show} onClose={() => setShow(false)} variant={variant}>
            <Alert.Heading>
                {variant === 'danger' ? 'Error:' : 'Success:'}
            </Alert.Heading>
            <p>{message}</p>
        </Alert>
    );
}
import React from 'react';
import ProgressBar from 'react-bootstrap/esm/ProgressBar';

export default function Progress({ limit, currVal, type='category'}) {
   if (!limit) {
        return (
            <ProgressBar 
                striped={true}
                variant={'secondary'} 
                label={type === 'category' ? 'NO LIMIT' : 'NO MAX'} 
                now={100} 
            />
        );
    }

    let progressVariant = 'primary';
    let progressValue = (currVal / limit) * 100;
    let progressLabel = type === 'budget' ? progressValue.toFixed(2) + '%' : progressValue.toFixed(0) + '%';

    if (currVal > limit) {
        progressLabel = type === 'category' ? 'OVER LIMIT' : 'OVER MAX';
    }

    if (progressValue > 80) progressVariant = 'warning';
    if (progressValue === 100) progressVariant = 'success';
    if (progressValue > 100) progressVariant = 'danger';

    return (
        <ProgressBar 
            striped={false}
            variant={progressVariant} 
            label={progressLabel} 
            now={progressValue} 
        />
    );
}
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CategoryArea from '../Category/CategoryArea';
import SummaryArea from '../Summary/SummaryArea';
import Alert from 'react-bootstrap/Alert';
import ExpenseDeleteModal from '../Expense/ExpenseDeleteModal';
import CategoryDeleteModal from '../Category/CategoryDeleteModal';
import { example } from '../../assets/example';
import { useParams } from 'react-router-dom';
import { convertTime } from '../../utils/convertTime';
import { getRandomColor } from '../../utils/getRandomColor';
import { v4 as uuidv4 } from 'uuid';

import './BudgetStyles.css';

// NEED TO MAKE A BUTTON AT THE TOP TO ADD CATEGORIES
// NEED TO ADJUST TOP TITLES TO MATCH ALL PAGES

export default function BudgetArea({ userData, axiosInstance }) {

	const [budget, setBudget] =  useState({});
    const [categories, setCategories] = useState([]);
	const [expenses, setExpenses] = useState([]);

    const [showCategoryModal, setShowCategoryModal] = useState(false);
	const [categoryModalDetails, setCategoryModalDetails] = useState({ id: '', name: '', totalDisplay: '', altCategories: [] });

	const [showExpenseModal, setShowExpenseModal] = useState(false);
	const [expenseModalDetails, setExpenseModalDetails] = useState({ id: '', description: '', amountDisplay: '' });

	const [showExampleBudgetWarning, setShowExampleBudgetWarning] = useState(true);

	const params = useParams();
	const budgetId = params.id;

	async function getBudget(budget_id) {
		try {
			const response = await axiosInstance.get(`budgets/${budget_id}`);
			

			if (response && response.status === 200) {
				setBudget(response.data[0]);
			} else {
				throw Error('Could not retrieve single budget from the database with id:', budget_id);
			}
		} catch (err) {
			console.error(err);
		}
	}

    async function updateBudgetTotal(budget_id) {
		const updateBudgetRequestBody = {
			total: expenses.length ? expenses.reduce((acc, expense) => expense.amount ? acc + expense.amount : acc + 0, 0) : 0,
			last_modified: convertTime(new Date(), 'toDatabase')
		}

		try {
			const response = await axiosInstance.put(`budgets/update/${budget_id}/total`, updateBudgetRequestBody);

			if (response && response.status === 200) {
				setBudget(currBudget => ({
					...currBudget,
					total: updateBudgetRequestBody.total,
					last_modified: updateBudgetRequestBody.last_modified
				}));
			} else {
				throw Error('Could not update budget with id:', budget_id);
			}
		} catch (err) {
			console.error(err);
		}
	}
	
	async function getCategories(budget_id) {
		try {
			const response = await axiosInstance.get(`categories/${budget_id}`);

			if (response && response.status === 200) {
				const retrievedCategories = [];
				response.data.forEach(category => {
					retrievedCategories.push({ ...category, color: getRandomColor(), is_new: false });
				});
				setCategories(retrievedCategories.sort((a, b) => a.position - b.position));
			} else {
				throw Error('Could not retrieve categories from the database');
			}
		} catch (err) {
			console.error(err);
		}
	}
	
	async function addNewCategory() {
		const newCategory = {
			id: uuidv4(),
			position: categories.length,
			name: '',
			color: getRandomColor(),
			category_limit: null,
			budget_id: budgetId,
            is_new: true
		}

		// Get number of unsaved categories to make title for error handling
		const unsavedCategories = categories.filter(category => category.name.includes('unsaved category'));
		const unsavedNums = unsavedCategories.map(category => parseInt(category.name.slice(-2, -1)));
		const newUnsavedNum = unsavedNums.length ? Math.max(...unsavedNums) + 1 : 0;

		const newCategoryRequestBody = {
			id: newCategory.id,
			position: newCategory.position,
			name: `unsaved category(${newUnsavedNum})`,
			budget_id: newCategory.budget_id
		};

		if (budgetId === 'example-budget') {
			setCategories([ ...categories, newCategory ]);
			return;
		}

		try {
			const response = await axiosInstance.post('categories/create', newCategoryRequestBody);
			
			if (response && response.status === 201) {
				setCategories([ ...categories, newCategory ]);
			} else {
				throw Error('Could not create a new category.');
			}
		} catch (err) {
			console.error(err);
		}
	}

    async function updateCategory(id, newName, newLimit) {
		const updateCategoryRequestBody = {
			name: newName,
			category_limit: newLimit
		};

		if (budgetId === 'example-budget') {
			setCategories(categories.map(category => {
				if (category.id === id) {
					return { ...category, name: newName, category_limit: newLimit, is_new: false }
				} else {
					return category;
				}
			}));
			return;
		}

		try {
			const response = await axiosInstance.put(`categories/update/${id}`, updateCategoryRequestBody);
			if (response && response.status === 200) {
				setCategories(categories.map(category => {
					if (category.id === id) {
						return { ...category, name: newName, category_limit: newLimit, is_new: false }
					} else {
						return category;
					}
				}));
			} else {
				throw Error('Could not edit category:', id);
			}
		} catch (err) {
			console.error(err);
		}
    }

	function changeCategoryColor(id) {
		setCategories(categories.map(category => {
			if (category.id === id) {
				return { ...category, color: getRandomColor() }
			} else {
				return category;
			}
		}));
	}

	function askDeleteCategory(id, name, totalDisplay) {
		setCategoryModalDetails({
			id: id,
			name: name,
			totalDisplay: totalDisplay,
			altCategories: categories.filter(category => category.id != id)
		});
		setShowCategoryModal(true);
	}

	function cancelDeleteCategory() {
		setShowCategoryModal(false);
	}

    async function deleteCategory(id) {
		// Get the position of the deleted category and make an updated category array
		const deletedCategoryPosition = categories.find(category => category.id === id).position;
		const updatedDeleteCategories = categories.filter(category => category.id !== id);
		const updatedPositionCategories = updatedDeleteCategories.map(category => {
			if (category.position > deletedCategoryPosition) {
				category.position = category.position - 1;
				return category;
			} 
			return category;
		});

		// If this is the example budget, set state values and stop function here
		if (budgetId === 'example-budget') {
			setCategories(updatedPositionCategories);
			setShowCategoryModal(false);
			return;
		}

		// Delete the selected category
		try {
			const deleteResponse = await axiosInstance.delete(`categories/delete/${id}`);

			const moveCategoriesRequestBody = {};
			updatedPositionCategories.forEach(category => moveCategoriesRequestBody[category.id] = category.position);
			const moveResponse = await axiosInstance.put('categories/move', moveCategoriesRequestBody);

			if (deleteResponse && deleteResponse.status === 200 && moveResponse && moveResponse.status === 200) {
				setCategories(updatedPositionCategories);
			} else {
				throw Error('Problem deleting category', id, 'and/or updating position of those remaining');
			}
		} catch (err) {
			console.error(err);
		} finally {
			setShowCategoryModal(false);
		}
    }
	
	async function getExpenses(budget_id) {
		try {
			const response = await axiosInstance.get(`expenses/${budget_id}`);
			if (response && response.status === 200) {
				setExpenses(response.data);
			} else {
				throw Error('Could not retrieve expenses from the database');
			}
		} catch (err) {
			console.error(err);
		}
	}

	async function addNewExpense(categoryId, expenseNum) {
		const newExpense = {
			id: uuidv4(),
			position: expenseNum,
			description: '',
			amount: '',
			category_id: categoryId,
			is_new: true
		}

		// Get number of unsaved expenses to make title for error handling
		const unsavedExpenses = expenses.filter(expense => expense.description.includes('unsaved expense'));
		const unsavedNums = unsavedExpenses.map(expense => parseInt(expense.description.slice(-2, -1)));
		const newUnsavedNum = unsavedNums.length ? Math.max(...unsavedNums) + 1 : 0;

		const newExpenseRequestBody = {
			id: newExpense.id,
			position: newExpense.position,
			description: `unsaved expense(${newUnsavedNum})`,
			category_id: newExpense.category_id
		};

		if (budgetId === 'example-budget') {
			setExpenses([ ...expenses, newExpense ]);
			return;
		}

		try {
			const response = await axiosInstance.post('expenses/create', newExpenseRequestBody);
			if (response && response.status === 201) {
				setExpenses([ ...expenses, newExpense ]);
			} else {
				throw Error('Could not create a new expense.');
			}
		} catch (err) {
			console.error(err);
		}
	}

	async function updateExpense(id, newDescription, newAmount) {
		const updateExpenseRequestBody = {
			description: newDescription,
			amount: newAmount
		};

		if (budgetId === 'example-budget') {
			setExpenses(expenses.map(expense => {
				if (expense.id === id) {
					return { ...expense, description: newDescription, amount: newAmount, is_new: false }
				} else {
					return expense;
				}
			}));
			return;
		}

		try {
			const response = await axiosInstance.put(`expenses/update/${id}`, updateExpenseRequestBody);
			if (response && response.status === 200) {
				setExpenses(expenses.map(expense => {
					if (expense.id === id) {
						return { ...expense, description: newDescription, amount: newAmount, is_new: false }
					} else {
						return expense;
					}
				}));
			} else {
				throw Error('Could not edit category:', id);
			}
		} catch (err) {
			console.error(err);
		}
	}

	async function moveExpenses(expenseIds, toCategoryId) {
		let newPositionNum = expenses.filter(expense => expense.category_id === toCategoryId).length;
		const moveDestinationExpensesRequestBody = { expensesToMove: {}, newCategory: toCategoryId };
		expenseIds.forEach(expenseId => {
			moveDestinationExpensesRequestBody.expensesToMove[expenseId] = newPositionNum;
			newPositionNum++;
		});

		if (budgetId === 'example-budget') {
			setExpenses(expenses.map(oldExpense => { 
				if (Object.keys(moveDestinationExpensesRequestBody.expensesToMove).includes(oldExpense.id)) { //FIX THIS LINE! WRONG COMPARISON! OLD EXPENSE NOT SAME OBJECT
					return { ...oldExpense, position: moveDestinationExpensesRequestBody.expensesToMove[oldExpense.id], category_id: toCategoryId }
				} else {
					return oldExpense;
				}
			}));
			return { status: 200 };
		}

		let response;
		try {
			response = await axiosInstance.put('expenses/move', moveDestinationExpensesRequestBody);
			if (response && response.status === 200) {
				setExpenses(expenses.map(oldExpense => { 
					if (Object.keys(moveDestinationExpensesRequestBody.expensesToMove).includes(oldExpense.id)) { //FIX THIS LINE! WRONG COMPARISON! OLD EXPENSE NOT SAME OBJECT
						return { ...oldExpense, position: moveDestinationExpensesRequestBody.expensesToMove[oldExpense.id], category_id: toCategoryId }
					} else {
						return oldExpense;
					}
				}));
			} else {
				throw Error('Could not move expenses');
			}
		} catch (err) {
			console.error(err);
		}
		return response;
	}

	function askDeleteExpense(id, description, amountDisplay) {
		setExpenseModalDetails({ 
			id: id, 
			description: description, 
			amountDisplay: amountDisplay 
		});
		setShowExpenseModal(true);
	}

	function cancelDeleteExpense() {
		setShowExpenseModal(false);
	}

	async function deleteExpenses(expenseIds) {
		const deleteExpensesRequestBody = { headers: {}, data: { expense_ids: [...expenseIds] } };

		if (budgetId === 'example-budget') {
			setExpenses(expenses.filter(expense => !expenseIds.includes(expense.id)));
			setShowExpenseModal(false);
			return { status: 200 };
		}

		let response;
		try {
			response = await axiosInstance.delete('expenses/delete', deleteExpensesRequestBody);
			if (response && response.status === 200) {
				setExpenses(expenses.filter(expense => !expenseIds.includes(expense.id)));
				setShowExpenseModal(false);
			} else {
				throw Error('Could not delete expense(s)');
			}
		} catch (err) {
			console.error(err);
		}
		return response;
	}

	async function handleDragDrop(result) {
		// Get the variables from the drag/drop result
		const { destination, source, draggableId, type } = result;

		// If there is no destination variable, or if the item was dropped in the same place, do nothing
		if (!destination) return;
		if (destination.droppableId === source.droppableId && destination.index === source.index) return;

		// If a category was moved...
		if (type === 'CATEGORY') {
			const movedCategory = categories.find(category => category.id === draggableId);
			let updatedCategories = [...categories];

			updatedCategories.splice(source.index, 1);
			updatedCategories.splice(destination.index, 0, movedCategory);
			updatedCategories = updatedCategories.map((category, index) => {
				category.position = index;
				return category;
			});

			if (budgetId === 'example-budget') {
				setCategories(updatedCategories);
				return;
			}

			const moveCategoriesRequestBody = {};
			updatedCategories.forEach(category => moveCategoriesRequestBody[category.id] = category.position);
			try {
				const response = await axiosInstance.put('categories/move', moveCategoriesRequestBody);
				if (response && response.status === 200) {
					setCategories(updatedCategories);
				} else {
					throw Error('Could not move categories');
				}
			} catch (err) {
				console.error(err);
			}

		// Otherwise, if an expense was moved...
		} else if (type === 'EXPENSE') {
			const movedExpense = expenses.find(expense => expense.id === draggableId);
			const sourceExpenses = expenses.filter(expense => expense.category_id === source.droppableId).sort((a, b) => a.position - b.position);
			sourceExpenses.splice(source.index, 1);

			// If the expense was moved into a different category...
			if (source.droppableId !== destination.droppableId) {
				const destinationExpenses = expenses.filter(expense => expense.category_id === destination.droppableId).sort((a, b) => a.position - b.position);
				destinationExpenses.splice(destination.index, 0, movedExpense);

				const updatedDestinationExpenses = destinationExpenses.map((expense, index) => {
					expense.category_id = destination.droppableId;
					expense.position = index;
					return expense;
				});

				if (budgetId === 'example-budget') {
					setExpenses(expenses.map(oldExpense => {
						const updatedExpense = updatedDestinationExpenses.find(newExpense => oldExpense.id === newExpense.id);
						return updatedExpense ? updatedExpense : oldExpense;
					}));
				} else {
					const moveDestinationExpensesRequestBody = { expensesToMove: {}, newCategory: destination.droppableId };
					updatedDestinationExpenses.forEach(expense => moveDestinationExpensesRequestBody.expensesToMove[expense.id] = expense.position);				
					try {
						const response = await axiosInstance.put('expenses/move', moveDestinationExpensesRequestBody);
						
		
						if (response && response.status === 200) {
							setExpenses(expenses.map(oldExpense => {
								const updatedExpense = updatedDestinationExpenses.find(newExpense => oldExpense.id === newExpense.id);
								return updatedExpense ? updatedExpense : oldExpense;
							}));
						} else {
							throw Error('Could not move expenses');
						}
					} catch (err) {
						console.error(err);
					}
				}
			} else { // Otherwise, just add the moved expense into the source category
				sourceExpenses.splice(destination.index, 0, movedExpense);
			}
			// Either way, now the source category expense position needs to be updated
			const updatedSourceExpenses = sourceExpenses.map((expense, index) => {
				expense.position = index;
				return expense;
			});

			if (budgetId === 'example-budget') {
				setExpenses(expenses.map(oldExpense => {
					const updatedExpense = updatedSourceExpenses.find(newExpense => oldExpense.id === newExpense.id);
					return updatedExpense ? updatedExpense : oldExpense;
				}));
				return;
			}

			const moveSourceExpensesRequestBody = { expensesToMove: {}, newCategory: false };
			updatedSourceExpenses.forEach(expense => moveSourceExpensesRequestBody.expensesToMove[expense.id] = expense.position);						
			try {
				const response = await axiosInstance.put('expenses/move', moveSourceExpensesRequestBody);
				if (response && response.status === 200) {
					setExpenses(expenses.map(oldExpense => {
						const updatedExpense = updatedSourceExpenses.find(newExpense => oldExpense.id === newExpense.id);
						return updatedExpense ? updatedExpense : oldExpense;
					}));
				} else {
					throw Error('Could not move expenses');
				}
			} catch (err) {
				console.error(err);
			}
		} else {
			console.error('ERROR: Drag/Drop item type not recognized.')
		}
	}

	// Retreive categories and expenses for the current budget from the database
	useEffect(() => {
		if (budgetId === 'example-budget') {
			setBudget(example.budget);
			setCategories(example.categories);
			setExpenses(example.expenses);
		} else {
			getBudget(budgetId);
			getCategories(budgetId);
			getExpenses(budgetId);
		}

		return () => {
			setBudget({});
			setCategories([]);
			setExpenses([]);
		}
	}, []);

	// Update the budget total and last_modified values in the database when expenses or categories change
	useEffect(() => {
		if (budgetId != 'example-budget') {
			updateBudgetTotal(budgetId)
		}
	}, [expenses, categories])

    return (
        <Container fluid className='budget-area'>

            { showCategoryModal && 
				<CategoryDeleteModal 
					show={showCategoryModal}
					id={categoryModalDetails.id}
					name={categoryModalDetails.name}
					totalDisplay={categoryModalDetails.totalDisplay}
					altCategories={categoryModalDetails.altCategories}

					expenseIds={expenses.filter(expense => expense.category_id === categoryModalDetails.id).map(expense => expense.id)}

					moveExpenses={moveExpenses}
					deleteExpenses={deleteExpenses}

					cancelDeleteCategory={cancelDeleteCategory}
					deleteCategory={deleteCategory}
				/>
			}

			{showExpenseModal &&
				<ExpenseDeleteModal 
					show={showExpenseModal} 
					id={expenseModalDetails.id}
					description={expenseModalDetails.description}
					amountDisplay={expenseModalDetails.amountDisplay}
					
					deleteExpenses={deleteExpenses}
					cancelDeleteExpense={cancelDeleteExpense}
				/>
			}

			{budgetId === 'example-budget' &&
				<Alert dismissible variant='warning' show={showExampleBudgetWarning} onClose={() => setShowExampleBudgetWarning(false)}>
					<Alert.Heading>Hey!</Alert.Heading>
					<p>This budget is just an example. Any changes made will not actually be saved.</p>
				</Alert>
			}

            <Row>
                <Col xs={12} lg={8}>
                    <CategoryArea 
                        budgetId={budgetId}
                        categories={categories}
                        expenses={expenses}

                        addNewCategory={addNewCategory}
                        updateCategory={updateCategory}
						changeCategoryColor={changeCategoryColor}
                        askDeleteCategory={askDeleteCategory}
                        deleteCategory={deleteCategory}

						addNewExpense={addNewExpense}
                        updateExpense={updateExpense}
                        askDeleteExpense={askDeleteExpense}
                        cancelDeleteExpense={cancelDeleteExpense}
                        deleteExpenses={deleteExpenses}
						handleDragDrop={handleDragDrop}
                        
                        localeInfo={userData.localeInfo} 
                    />
                </Col>

				<Col xs={12} lg={4}>
                    <SummaryArea 
						categories={categories} 
						expenses={expenses} 
						title={budget.title}
						max={budget.max}
						total={budget.total}
						details={budget.details}
						localeInfo={userData.localeInfo} 
					/>
                </Col>
            </Row>
        </Container>
    );
}
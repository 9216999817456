import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import BudgetCard from '../components/Budget/BudgetCard';
import BudgetToolbar from '../components/Budget/BudgetToolbar';
import BudgetCreateModal from '../components/Budget/BudgetCreateModal';
import BudgetDeleteModal from '../components/Budget/BudgetDeleteModal';
import Header from '../components/Header/Header';
import { useNavigate } from 'react-router-dom'; 
import { example } from '../assets/example';
import { convertTime } from '../utils/convertTime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import './PageStyles.css';

// NEED TO ADD ALERT MESSAGE ERROR HANDLING TO THE NETWORK CALLS HERE

export default function Budgets({ userData, axiosInstance }) {
    const navigate = useNavigate();
    const [budgets, setBudgets] = useState([]);
    const [selectedBudget, setSelectedBudget] = useState({ id: '', title: '', max: '', details: ''});

    const [searchTerm, setSearchTerm] = useState('');
    const [sortOption, setSortOption] = useState('title');
    const [sortAsc, setSortAsc] = useState(true);

    const [showBudgetCreateModal, setShowBudgetCreateModal] = useState(false);
    const [showBudgetDeleteModal, setShowBudgetDeleteModal] = useState(false);

    async function getBudgets(user_id) {
		try {
			const response = await axiosInstance.get(`budgets/all/${user_id}`);
			if (response && response.status === 200) {
                const responseBudgets = response.data;
                if (userData.useExample) {
                    responseBudgets.push(example.budget);
                }
				setBudgets(responseBudgets);
			} else {
				throw Error('Could not retrieve budgets from the database');
			}
		} catch (err) {
			console.error(err);
		}
	}

    async function addNewBudget(id, title, max, details) {
		const newBudget = {
			id: id,
			title: title,
			max: max,
			details: details,
            total: 0.00,
            created_at: convertTime(new Date(), 'toDatabase'),
            last_modified: convertTime(new Date(), 'toDatabase'),
            user_id: userData.id
		}

		try {
			const response = await axiosInstance.post('budgets/create', newBudget);
			if (response && response.status === 201) {
				setBudgets([ ...budgets, newBudget ]);
                return newBudget;
			} else {
				throw Error('Could not create a new budget.');
			}
		} catch (err) {
			console.error(err);
		}
	}

    async function updateBudget(budget_id, newTitle, newMax, newDetails) {
        const updateBudgetRequestBody = {
			title: newTitle,
            max: newMax,
            details: newDetails
		};

		try {
			const response = await axiosInstance.put(`budgets/update/${budget_id}`, updateBudgetRequestBody);
			if (response && response.status === 200) {
				setBudgets(budgets.map(budget => {
					if (budget.id === budget_id) {
						return { ...budget, title: newTitle, max: newMax, details: newDetails }
					} else {
						return budget;
					}
				}));
			} else {
				throw Error('Could not edit budget:', budget_id);
			}
		} catch (err) {
			console.error(err);
		}
    }

    function askDeleteBudget(id, title, max, details) {
		setSelectedBudget({
			id: id,
			title: title,
			max: max,
			details: details
		});
		setShowBudgetDeleteModal(true);
	}

	function cancelDeleteBudget() {
        setSelectedBudget({ id: '', title: '', max: '', details: '' });
		setShowBudgetDeleteModal(false);
	}

    async function deleteBudget(id) {
		try {
			const response = await axiosInstance.delete(`budgets/delete/${id}`);
			if (response && response.status === 200) {
				setBudgets(budgets.filter(budget => budget.id !== id));
                setSelectedBudget({ id: '', title: '', max: '', details: '' });
				setShowBudgetDeleteModal(false);
			} else {
				throw Error('Could not delete category:', id);
			}
		} catch (err) {
			console.error(err);
		}
    }

    async function copyBudget(copyFromBudgetId, newBudgetId) {
        const copyBudgetRequestBody = {
			copyFromBudgetId: copyFromBudgetId,
            newBudgetId: newBudgetId,
		};
        
        try {
			const response = await axiosInstance.post('budgets/copy', copyBudgetRequestBody);
			if (response && response.status === 200) {
                getBudgets(userData.id);
                navigate(`/budgets/${newBudgetId}`)
			} else {
				throw Error('Could not copy budget');
			}
		} catch (err) {
			console.error(err);
		}
    }

    useEffect(() => {
        getBudgets(userData.id);
    }, []);

    let displayBudgets = [...budgets];
    if (searchTerm.trim()) {
        displayBudgets = displayBudgets.filter(budget => budget.title.toUpperCase().includes(searchTerm.toUpperCase()));
    }

    displayBudgets = displayBudgets.sort((a, b) => {
        if (sortOption === 'title') {
            return a[sortOption].localeCompare(b[sortOption], userData.localeInfo.languageCode, 'base');
        } 
        if (sortOption === 'max' || sortOption === 'total') {
            return Number(a[sortOption]) - Number(b[sortOption]);
        }
        return new Date(a[sortOption]) - new Date(b[sortOption]);
    });

    if (!sortAsc) {
        displayBudgets = displayBudgets.reverse();
    }

    const budgetCards = displayBudgets.map(budget => (
        <Col xs={12} sm={6} lg={4} xl={3} key={budget.id}>
            <BudgetCard
                id={budget.id}
                title={budget.title}
                details={budget.details}
                max={budget.max}
                total={budget.total}
                
                setSelectedBudget={setSelectedBudget}
                setShowBudgetCreateModal={setShowBudgetCreateModal}
                askDeleteBudget={askDeleteBudget}

                localeInfo={userData.localeInfo}
            />
        </Col>
    ));

    return (
        <Container fluid className='page-container'>
            {showBudgetCreateModal &&
                <BudgetCreateModal 
                    show={showBudgetCreateModal}
                    id={selectedBudget.id}
                    title={selectedBudget.title}
                    max={selectedBudget.max}
                    details={selectedBudget.details}
                    allOtherBudgets={budgets.filter(budget => budget.id != selectedBudget.id && budget.id != 'example-budget')}

                    setShowBudgetCreateModal={setShowBudgetCreateModal}
                    addNewBudget={addNewBudget}
                    copyBudget={copyBudget}
                    updateBudget={updateBudget}

                    localeInfo={userData.localeInfo}
                />
            }
            {showBudgetDeleteModal &&
                <BudgetDeleteModal
                    show={showBudgetDeleteModal}
                    id={selectedBudget.id}
                    title={selectedBudget.title}

                    cancelDeleteBudget={cancelDeleteBudget}
                    deleteBudget={deleteBudget}
                />
            }
            <Header headerTitle='Budgets'>
                <Button variant='outline-primary' className='mt-3' onClick={() => setShowBudgetCreateModal(true)}>
                    <h2><FontAwesomeIcon icon={faPlus} /> New Budget</h2>
                </Button>
            </Header>
            
            <BudgetToolbar 
                searchTerm={searchTerm}
                sortOption={sortOption}
                sortAsc={sortAsc}

                setSearchTerm={setSearchTerm} 
                setSortOption={setSortOption} 
                setSortAsc={setSortAsc} />

            <Row>
                {budgetCards}
            </Row>
        </Container>
    );
}
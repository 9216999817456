import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

import './Loader.css';

export default function Loader({ showLoader }) {
    return (
        <Modal centered backdrop='static' show={showLoader} className='loader-container'>
            <Spinner animation='border' variant='success' />
        </Modal>
    );
}
import React from 'react';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines, faPencil, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { currencyFormatter } from '../../utils/currencyFormatter';

import './ExpenseStyles.css';

export default function ExpenseDisplay({ id, description, amount, expenseTotal, limit, setIsEditing, askDeleteExpense, localeInfo, dragHandleProps }) {
        
    function handleEdit() {
        setIsEditing(true);
    }

    const amountDisplay = currencyFormatter(localeInfo.languageCode, localeInfo.currencyCode).format(amount);
    const percentDisplay = '~' + Math.round((amount / (limit ? limit : expenseTotal)) * 100) + '%';

    return (
        <>
            <Col xs={1} className='expense-drag-icon' {...dragHandleProps}>
                <FontAwesomeIcon icon={faGripLines} size='lg' />
            </Col>
            <Col xs={5} sm={4} className='d-flex border' title={description}>
                <div className='expense-description'>
                    {description}
                </div>
            </Col>
            <Col xs={4} sm={3} className='d-flex align-items-center justify-content-center border'>{amountDisplay}</Col>
            <Col xs={2} className='d-none d-sm-flex align-items-center justify-content-center border'>{percentDisplay}</Col>
            <Col xs={2} className='d-flex align-items-center justify-content-center border p-0'>
                <ButtonGroup size='sm' className='w-100'>
                    <Button variant='outline-primary' className='expense-btn' onClick={handleEdit}>
                        <FontAwesomeIcon icon={faPencil} />
                    </Button>
                    <Button variant='outline-danger' className='expense-btn' onClick={() => askDeleteExpense(id, description, amountDisplay)}>
                        <FontAwesomeIcon icon={faTrashCan} />
                    </Button>
                </ButtonGroup>
                {/* <div className='expense-edit-icons'>
                    <FontAwesomeIcon 
                        icon={faPencil}  
                        className='mx-2 text-primary expense-edit-icon'
                        onClick={handleEdit}
                    />
                    <FontAwesomeIcon 
                        icon={faTrashCan}  
                        className='mx-2 text-danger expense-edit-icon'
                        onClick={() => askDeleteExpense(id, description, amountDisplay)}
                    />
                </div> */}
            </Col>
        </>
    );
}
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faSort, faArrowUpAZ, faArrowDownZA } from '@fortawesome/free-solid-svg-icons';

import './BudgetStyles.css';

export default function BudgetToolbar({ searchTerm, sortOption, sortAsc, setSearchTerm, setSortOption, setSortAsc }) {
    function searchTermDisplay(option) {
        switch(option) {
            case 'title':
                return 'Title';
            case 'max':
                return 'Max';
            case 'total':
                return 'Total';
            case 'created_at':
                return 'Created';
            case 'last_modified':
                return 'Recent Activity';
            default:
                return 'Error';
        }
    }

    return (
        <Row className='m-4 budget-toolbar'>
            <Col xs={12} md={6} className='d-flex my-3'>
                <Button disabled className='w-25 p-0' variant='light' size='lg' style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}>
                    <strong><span className='d-none d-sm-inline'>Search</span> <FontAwesomeIcon icon={faMagnifyingGlass} /></strong>
                </Button>
                <Form.Control 
                    type='text'
                    size='lg'
                    required={false}
                    value={searchTerm}
                    maxLength={255}
                    placeholder='Search Budget Titles'
                    className='w-75'
                    style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                    onChange={(e) => setSearchTerm(e.currentTarget.value)}
                />
            </Col>
            <Col xs={12} md={6} className='d-flex my-3 justify-content-center'>
                <Button disabled className='w-25 p-0' variant='light' size='lg' style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}>
                    <strong><span className='d-none d-sm-inline'>Sort </span> <FontAwesomeIcon icon={faSort} /></strong>
                </Button>

                <Dropdown className='w-50' onSelect={(eventKey) => setSortOption(eventKey)}>

                    <Dropdown.Toggle variant='light' className='w-100' style={{borderRadius: 0}}>
                        <strong>{searchTermDisplay(sortOption)}</strong>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='w-100'>
                        <Dropdown.Item eventKey={'title'}>Title</Dropdown.Item>
                        <Dropdown.Item eventKey={'max'}>Max</Dropdown.Item>
                        <Dropdown.Item eventKey={'total'}>Total</Dropdown.Item>
                        <Dropdown.Item eventKey={'created_at'}>Created</Dropdown.Item>
                        <Dropdown.Item eventKey={'last_modified'}>Recent Activity</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <ToggleButtonGroup type="radio" name="sorting-toggle-btns" className='w-25' value={sortAsc} onChange={(newVal) => setSortAsc(newVal)}>
                    <ToggleButton id="asc-toggle" type='radio' variant='outline-light' className='w-50' value={true} style={{borderRadius: 0}}>
                        <FontAwesomeIcon icon={faArrowUpAZ} />
                    </ToggleButton>
                    <ToggleButton id="desc-toggle" type='radio' variant='outline-light' className='w-50' value={false}>
                        <FontAwesomeIcon icon={faArrowDownZA} />
                    </ToggleButton>
                </ToggleButtonGroup>
                
            </Col>
        </Row>
    );
}
import React from 'react';
import Collapse from 'react-bootstrap/Collapse';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ExpenseHeader from './ExpenseHeader';
import ExpenseRow from './ExpenseRow';
import Progress from '../Progress/Progress';
import { Droppable } from '@hello-pangea/dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { currencyFormatter } from '../../utils/currencyFormatter.js';

import './ExpenseStyles.css';

export default function ExpenseArea({ categoryOpen, categoryId, limit, categoryMoveInfo, expenses, addNewExpense, updateExpense, askDeleteExpense, deleteExpenses, showAddExpenseBtn, setShowAddExpenseBtn, localeInfo }) {

    const expenseTotal = expenses.reduce((a, b) => a + b.amount, 0);
    const expenseTotalDisplay = currencyFormatter(localeInfo.languageCode, localeInfo.currencyCode).format(expenseTotal);
    const limitDisplay = limit ?' / ' + currencyFormatter(localeInfo.languageCode, localeInfo.currencyCode).format(limit) : ' / no limit';

    function handleAddExpense() {
        addNewExpense(categoryId, expenses.length);
        setShowAddExpenseBtn(false);
    }

    const expenseRows = expenses.sort((a, b) => a.position - b.position).map((item, index) => {
        return (
            <ExpenseRow 
                key={item.id} 
                id={item.id}
                index={index} 
                description={item.description}
                amount={item.amount}
                expenseTotal={expenseTotal}
                isNew={item.is_new}

                limit={limit}
                categoryMoveInfo={categoryMoveInfo}

                setShowAddExpenseBtn={setShowAddExpenseBtn}
                updateExpense={updateExpense}
                askDeleteExpense={askDeleteExpense}
                deleteExpenses={deleteExpenses}

                localeInfo={localeInfo}
            />
        );
    });

    const expenseBtnClassses = showAddExpenseBtn ? 'expense-add-btn' : ' d-none';

    return (
        <Collapse in={categoryOpen} className='expense-collapse m-auto'>
            <div>
                <Droppable droppableId={categoryId} direction='vertical' type='EXPENSE'>
                    {(provided, snapshot) => (

                        <Container 
                            className={snapshot.isDraggingOver ? 'expense-area-drag' : ''}
                            ref={provided.innerRef} 
                            {...provided.droppableProps}
                        >

                            <Row className=' d-inline-flex d-sm-none w-100 mt-2 align-items-center'>
                                <Col xs={6} className='mb-1'>
                                    <strong>{expenseTotalDisplay}</strong>{limitDisplay}
                                </Col>

                                <Col xs={6} className='mb-1'>
                                    <Progress limit={limit} currVal={expenseTotal} type='category' />
                                </Col>
                                <hr />
                            </Row>

                            <Row>
                                <Col className='text-center my-3' style={{color: '#000'}}>
                                    <h4>{expenseRows.length} Expenses</h4>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} sm={11} lg={10} className='mx-auto expense-table-area'>
                                    <ExpenseHeader limit={limit} />
                                    <Container fluid className='m-0 p-0'>
                                        {expenseRows}
                                    </Container>
                                </Col>
                            </Row>

                            {provided.placeholder}

                            <Row className='mt-0'>
                                <Col xs={12} sm={11} lg={10} className='mx-auto mb-sm-4 p-0'>
                                    <Button 
                                        variant='outline-success' 
                                        className={expenseBtnClassses} 
                                        onClick={handleAddExpense}
                                        > New Expense <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    )}
                </Droppable>
            </div>
        </Collapse>
    );
}

import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Header from '../Header/Header';
import CategoryHeader from './CategoryHeader';
import CategoryRow from './CategoryRow';
import AlertMessage from '../AlertMessage/AlertMessage';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import './CategoryStyles.css';

export default function CategoryArea({ budgetId, categories, expenses, addNewCategory, updateCategory, changeCategoryColor, askDeleteCategory, deleteCategory, addNewExpense, updateExpense, askDeleteExpense, cancelDeleteExpense, deleteExpenses, handleDragDrop, localeInfo }) {
	const [showAlert, setShowAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');
	
    const categoryRows = categories.sort((a, b) => a.position - b.position).map(item => {
		return (
			<CategoryRow 
				key={item.id}
				id={item.id}
				position={item.position}
				name={item.name}
				color={item.color}
				limit={item.category_limit}
				is_new={item.is_new}
				expenses={expenses.filter(expense => expense.category_id === item.id)}
				categoryNames={categories.map(category => category.name)}
				anyCategoryIsNew={categories.filter(category => category.is_new).length > 0}

				updateCategory={updateCategory}
				changeCategoryColor={changeCategoryColor}
				askDeleteCategory={askDeleteCategory}
				deleteCategory={deleteCategory}

				addNewExpense={addNewExpense}
				updateExpense={updateExpense}
				askDeleteExpense={askDeleteExpense}
				cancelDeleteExpense={cancelDeleteExpense}
				deleteExpenses={deleteExpenses}

				setShowAlert={setShowAlert}
				setAlertMessage={setAlertMessage}

				localeInfo={localeInfo}
			/>
		);
	});

    return (
        <Container fluid className="category-area">

			<Header headerTitle='Categories' />

            <CategoryHeader />
			
			<Row>
				<Col>
					<DragDropContext onDragEnd={handleDragDrop}>
						<Droppable droppableId={budgetId} direction='vertical' type='CATEGORY'>
							{provided => (
								<div className='p-0' {...provided.droppableProps} ref={provided.innerRef}>
									{categoryRows}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</Col>
			</Row>

            <Row className={categories.filter(category => category.is_new).length > 0 ? 'd-none' : 'category justify-content-center'}>
				<Col xs={12} className='p-0'>
					<Button 
						variant='outline-primary' 
						className='category-add-new-btn' 
						onClick={addNewCategory}
						>New Category <FontAwesomeIcon icon={faPlus} />
					</Button>
				</Col>
			</Row>
			
			<Row className='my-3 justify-content-center'>
				<Col xs={12} sm={10} md={9}>
					<AlertMessage 
						show={showAlert} 
						setShow={setShowAlert} 
						variant='danger'
						message={alertMessage}
						autoRemove={5000}
					/>
				</Col>
			</Row>
			
        </Container>
    );
}
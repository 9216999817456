import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFloppyDisk, faXmark } from '@fortawesome/free-solid-svg-icons'

import './ExpenseStyles.css';

export default function ExpenseInput({ id, currDescription, currAmount, isNew, setShowAddExpenseBtn, setIsEditing, updateExpense, deleteExpenses, localeInfo }) {
    const [validated, setValidated] = useState(false);
    const [description, setDescription] = useState(currDescription ? currDescription : '');
    const [amount, setAmount] = useState(currAmount ? currAmount : '');

    function handleSave(event) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);

        if (event.currentTarget.checkValidity() === true) {
            updateExpense(id, description, amount);
            setIsEditing(false);
            setShowAddExpenseBtn(true);
        }
    }

    function handleCancel(event) {
        event.stopPropagation();
        if (isNew) {
            deleteExpenses([id]);
        }
        setIsEditing(false);
        setShowAddExpenseBtn(true);
    }

    function handleDescriptionChange(e) {
        setDescription(e.currentTarget.value);
    }

    function handleAmountChange(e) {
        const value = e.currentTarget.value;
        if (isNaN(value)) setAmount('');
        else setAmount(parseFloat(value));
    }

    return (
        <Form 
            noValidate 
            validated={validated} 
            onSubmit={handleSave}
            className='text-center m-auto'
        >
            <Row className='align-items-center expense-row-input'>
                <Form.Group as={Col} xs='5' className='p-0 h-100 border' controlId='validationExpenseDescription'>
                    <Form.Control 
                        required
                        autoFocus
                        size='sm'
                        className='h-100'
                        type='text'
                        value={description}
                        placeholder='Description'
                        maxLength={255}
                        onChange={(e) => handleDescriptionChange(e)}
                    />
                </Form.Group>

                <Form.Group as={Col} xs='4' sm='3' className='border p-0' controlId='validationExpenseAmount'>
                    <InputGroup hasValidation size='sm'>
                        <InputGroup.Text>{localeInfo.currencySymbol}</InputGroup.Text>
                        <Form.Control 
                            required
                            type='number'
                            size='sm'
                            min='0'
                            max='10000000'
                            step='1.00'
                            value={amount}
                            placeholder='Amount'
                            onChange={handleAmountChange}
                        />
                    </InputGroup>
                </Form.Group>

                <Col xs='3' sm='4' className='h-100 p-0 text-center border'>
                    <ButtonGroup size='sm' className='w-100 h-100'>
                        <Button variant='outline-success' className='expense-input-btn' type='submit'>
                            <span className='d-none d-md-inline'>Save</span> <FontAwesomeIcon icon={faFloppyDisk} />
                        </Button>
                        <Button 
                            variant='outline-secondary' 
                            className='expense-input-btn' 
                            type='button' 
                            onClick={(e) => handleCancel(e)}
                            ><span className='d-none d-md-inline'>Cancel</span> <FontAwesomeIcon icon={faXmark} />
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </Form>
    );
}
import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import AlertMessage from '../components/AlertMessage/AlertMessage';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { checkValidEmail } from '../utils/checkValidEmail';
import { checkValidPassword } from '../utils/checkValidPassword';
import { updateTheme } from '../utils/updateTheme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faRightToBracket, faUserPlus } from '@fortawesome/free-solid-svg-icons';

import './PageStyles.css';

// INVESTIGATE AND FIX WHY USING TEXT-CENTER FOR PARENT CONTAINER AND THEN TEXT-START PER COLUMN

export default function Login({ userData, setUserData, setIsAuthenticated, axiosInstance, handleLogout }) {
    const [isLogin, setIsLogin] = useState(true);
    const [validated, setValidated] = useState(false);
    const [displayName, setDisplayName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('danger');
    const [alertMessage, setAlertMessage] = useState('');

    const passwordDescription = 'Passwords must be 8-32 characters and include at least 1 number, 1 upper case, and 1 special character.'

    function handleSubmitLogin(event) {
        event.preventDefault();

        if (!isLogin && password != confirmPassword) {
            const errorMessage = 'The submitted password and confirmation are not the same.  Please try again.';
            event.currentTarget[3].setCustomValidity(errorMessage);
            setAlertMessage(errorMessage);
            setShowAlert(true);
        } else if (!checkValidEmail(email)) {
            const errorMessage = 'The submitted email is not valid.  Please try again with a valid email.';
            const targetIndex = isLogin ? 0 : 1
            event.currentTarget[targetIndex].setCustomValidity(errorMessage);
            setAlertMessage(errorMessage);
            setShowAlert(true);
        } else if (!checkValidPassword(password)) {
            const errorMessage = 'The submitted password is not valid. ' + passwordDescription;
            const targetIndex = isLogin ? 1 : 2
            event.currentTarget[targetIndex].setCustomValidity(errorMessage);
            setAlertMessage(errorMessage);
            setShowAlert(true);
        }
        setValidated(true);
        if (event.currentTarget.checkValidity() === true) {
            if (isLogin) {
                sendLogin(email, password)
            } else {
                sendRegister(displayName, email, password)
            }
        } 
    }

    function handleUpdateEmail(e) {
        if (checkValidEmail(e.currentTarget.value)) {
            e.currentTarget.setCustomValidity('');
        }
        setEmail(e.currentTarget.value);
    }

    function handleUpdatePassword(e) {
        if (checkValidPassword(e.currentTarget.value)) {
            e.currentTarget.setCustomValidity('');
        }
        setPassword(e.currentTarget.value);
    }

    function handleUpdateConfirmPassword(e) {
        if (e.currentTarget.value.length > 7 && e.currentTarget.value === password) {
            e.currentTarget.setCustomValidity('');
        }
        setConfirmPassword(e.currentTarget.value);
    }

    function handleSwitchLogin() {
        setDisplayName('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setIsLogin(!isLogin);
    }

    async function sendRegister(displayName, email, password) {
        const sendRegisterRequestBody = {
            id: uuidv4(),
            email: email,
            password: password,
            display_name: displayName
        }

        let response;
        let errorMessage;

        try {
            response = await axiosInstance.post('users/register', sendRegisterRequestBody);
            if (response && response.status === 201) {
                sendLogin(email, password);
            } else if (response.response.status === 400) {
                errorMessage = 'The server encountered credentials that do not meet the expected email and password requirements.';
            } else if (response.response.status === 409) {
                errorMessage = 'An account for this email already exists.  Please try to login instead.';
            } else {
                errorMessage = 'Unknown Error';
            }
        } catch (err) {
            console.error(err);
            errorMessage = `The server encountered a problem.  Please try again later.  (${err})`;
        } finally {
            if (errorMessage) {
                handleLogout();
                setAlertType('danger');
                setAlertMessage('Authentication Error: ' + errorMessage);
            } else {
                setAlertType('success');
                setAlertMessage('Your account was created!');
            }
            setShowAlert(true);
        }
    }

    async function sendLogin(email, password) {
        const sendLoginRequestBody = { email: email, password: password };
        let response;
        let errorMessage;

        try {
            response = await axiosInstance.post('users/login', sendLoginRequestBody);
            if (response && response.status === 200) {
                setIsAuthenticated(true);
                updateTheme(response.data.theme)
                setUserData({ ...userData, id: response.data.id, displayName: response.data.display_name, useExample: response.data.use_example ? true : false, theme: response.data.theme });

                localStorage.setItem('jwt', JSON.stringify(response.data.accessToken));
                localStorage.setItem('uid', JSON.stringify(response.data.id));
                localStorage.setItem('display', JSON.stringify(response.data.display_name));
                localStorage.setItem('theme', JSON.stringify(response.data.theme));
                localStorage.setItem('example', JSON.stringify(response.data.use_example ? true : false));

                axios.defaults.headers.common['authorization'] = `Bearer ${response.data.accessToken}`;
                axios.defaults.headers.common['id'] = response.data.id;
                axiosInstance.defaults.headers.common['authorization'] = `Bearer ${response.data.accessToken}`;
                axiosInstance.defaults.headers.common['id'] = response.data.id;

            } else if (!response) {
                errorMessage = 'The server did not respond. Please try again later.';
            } else if (response.response.status === 401) {
                errorMessage = 'You have entered a wrong email or password.  Please try again.';
            } else if (response.response.status === 404) {
                errorMessage = 'The account was not found.  Please try again.';
            } else {
                errorMessage = 'Unknown Error';
            }
        } catch (err) {
            console.error(err);
            errorMessage = `The server encountered a problem.  Please try again later.  (${err})`;        
        } finally {
            if (errorMessage) {
                setIsAuthenticated(false);
                setAlertType('danger');
                setAlertMessage('Authentication Error: ' + errorMessage);
            } else {
                setAlertType('success');
                setAlertMessage('You are now logged in!');
            }
            setShowAlert(true);
        }
    }

    return (
        <Container fluid className='page-container'>
            <Row>
                <AlertMessage 
                    show={showAlert}
                    setShow={setShowAlert}
                    variant={alertType}
                    message={alertMessage}
                />
            </Row>
            
            <Row className="login-main">
                <Col xs={12} sm={8} lg={6} className='mx-auto text-center' style={{height: '90%'}}>
                    <div style={{height: '100%'}}>
                        <Card className={isLogin ? 'login-card' : 'register-card'}>

                            <Card.Header className={isLogin ? 'login-header' : 'register-header'}>
                                <Card.Title>
                                    {isLogin ? 'Welcome Back!' : "Let's Get Started!"}
                                </Card.Title>
                            </Card.Header>

                            <Card.Body className={isLogin ? 'login-body' : 'register-body'}>
                                <Form
                                    noValidate 
                                    validated={validated} 
                                    onSubmit={handleSubmitLogin}
                                    className='h-100 text-center'
                                >
                                    { !isLogin && <Row className='h-25'>
                                        <Col xs={12} sm={8} className='m-auto text-start'>
                                            <Form.Label>Name <Form.Text>{'(optional)'}</Form.Text></Form.Label>
                                            <Form.Control 
                                                required
                                                type='text'
                                                size='lg'
                                                value={displayName}
                                                placeholder='Display Name'
                                                maxLength={255}
                                                onChange={(e) => setDisplayName(e.currentTarget.value)}
                                            />
                                        </Col>
                                    </Row> }

                                    <Row className='h-25'>
                                        <Col xs={12} sm={8} className='m-auto text-start'>
                                            <Form.Label>Email *</Form.Label>
                                            <Form.Control 
                                                required
                                                autoFocus
                                                type='email'
                                                size='lg'
                                                value={email}
                                                placeholder='email@address.com'
                                                maxLength={255}
                                                onChange={(e) => handleUpdateEmail(e)}
                                            />
                                        </Col>
                                    </Row>

                                    <Row className='h-25'>
                                        <Col xs={12} sm={8} className='m-auto text-start'>
                                            <Form.Label>
                                                Password * {' '}
                                                {!isLogin &&  
                                                    <OverlayTrigger 
                                                        placement='right' 
                                                        trigger={['hover', 'click']} 
                                                        overlay={<Tooltip>{passwordDescription}</Tooltip>}
                                                    >
                                                        <FontAwesomeIcon icon={faCircleInfo} className='pasword-info-icon' />
                                                    </OverlayTrigger>
                                                }
                                            </Form.Label>
                                            <Form.Control 
                                                required
                                                type='password'
                                                size='lg'
                                                value={password}
                                                placeholder='Password'
                                                maxLength={255}
                                                minLength={8}
                                                onChange={(e) => handleUpdatePassword(e)}
                                            />
                                            {!isLogin && <Form.Control 
                                                required
                                                type='password'
                                                size='lg'
                                                value={confirmPassword}
                                                placeholder='Confirm Password'
                                                maxLength={255}
                                                minLength={8}
                                                onChange={(e) => handleUpdateConfirmPassword(e)}
                                            /> }
                                        </Col>
                                    </Row>

                                    <Row className='h-25'>
                                        <Col xs={12} sm={8} className='m-auto'>
                                            <Button size='lg' type='submit' variant='outline-success' className='w-100'>
                                                {isLogin ? 'Login ' : 'Register '}
                                                {isLogin 
                                                    ? <FontAwesomeIcon icon={faRightToBracket} />
                                                    : <FontAwesomeIcon icon={faUserPlus} />
                                                }
                                            </Button>
                                        </Col>
                                    </Row>

                                </Form>
                            </Card.Body>

                            <Card.Footer className={isLogin ? 'login-footer' : 'register-footer'}>
                                <Card.Subtitle>
                                    {isLogin ? "Don't have an account?" : "Already have an account?"}
                                </Card.Subtitle>
                                <Button size='lg' variant={isLogin ? 'outline-dark' : 'outline-light'} onClick={handleSwitchLogin}>
                                    {isLogin ? 'Register Here' : 'Login Here'}
                                </Button>
                            </Card.Footer>
                        </Card>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
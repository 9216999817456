import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import ExpenseDisplay from './ExpenseDisplay';
import ExpenseInput from './ExpenseInput';
import { Draggable } from '@hello-pangea/dnd';

import './ExpenseStyles.css';

export default function ExpenseRow({ id, index, description, amount, expenseTotal, isNew, limit, setShowAddExpenseBtn, updateExpense, askDeleteExpense, deleteExpenses, localeInfo }) {
    const [isEditing, setIsEditing] = useState(isNew);
   
    return (
        <Draggable draggableId={id} index={index} isDragDisabled={isEditing}>
            {(provided, snapshot) => (
                <Row
                    className={'expense-row' + (snapshot.isDragging ? ' expense-row-moving' : '')} 
                    {...provided.draggableProps} 
                    ref={provided.innerRef}
                >
                    {isEditing 
                        ? <ExpenseInput
                            id={id}
                            expenseNum={index+1}
                            currDescription={description}
                            currAmount={amount}
                            isNew={isNew}
            
                            setShowAddExpenseBtn={setShowAddExpenseBtn}
                            setIsEditing={setIsEditing}
                            updateExpense={updateExpense}
                            deleteExpenses={deleteExpenses}
            
                            localeInfo={localeInfo}
                        />
                        : <ExpenseDisplay
                            id={id}
                            description={description}
                            amount={amount}
                            expenseTotal={expenseTotal}
                
                            limit={limit}
                
                            setIsEditing={setIsEditing}
                            askDeleteExpense={askDeleteExpense}
                
                            localeInfo={localeInfo}
                            dragHandleProps={{...provided.dragHandleProps}}
                        />
                    }
                </Row>
            )}
        </Draggable>
    );
}

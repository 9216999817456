import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons';

import './CategoryStyles.css';

export default function ExpenseDeleteModal({ show, id, name, totalDisplay, altCategories, expenseIds, moveExpenses, deleteExpenses, cancelDeleteCategory, deleteCategory }) {
    const [deleteAllExpenses, setDeleteAllExpenses] = useState(true);
    const [moveToCategoryId, setMoveToCategoryId] = useState(null);
    const [moveToCategoryName, setMoveToCategoryName] =  useState(null);

    function handleCancel() {
        setDeleteAllExpenses(true);
        setMoveToCategoryId(null);
        setMoveToCategoryName(null);
        cancelDeleteCategory();
    }

    function deleteAndReset(id) {
        deleteCategory(id);
        setMoveToCategoryId(null);
        setMoveToCategoryName(null);
    }

    async function handleDeleteCategory() {
        if (expenseIds.length) {
            let response;
            try {
                if (deleteAllExpenses) {
                    response = await deleteExpenses(expenseIds);
                } else {
                    response = await moveExpenses(expenseIds, moveToCategoryId);
                }
            } catch (err) {
                console.error(err)
            }

            if (response && response.status === 200) {
                deleteAndReset(id);
            }
        } else {
            deleteAndReset(id);
        }
    }

    const categoryOptions = altCategories.map(altCategory => (
        <Dropdown.Item 
            key={altCategory.id} 
            eventKey={altCategory.id} 
            onClick={() => setMoveToCategoryName(altCategory.name)}
            >{altCategory.name}
        </Dropdown.Item>
    ));

    return (
        <Modal show={show} onHide={handleCancel}>

            <Modal.Header closeButton className='category-modal-header'>
                <Modal.Title>Delete Category?</Modal.Title>
            </Modal.Header>

            <Modal.Body className='category-modal-body'>
                <Container>
                    <Row>
                        <Col>You are about to delete:</Col>
                    </Row>
                    <Row>
                        <Col className='mt-5 text-center'>
                            <ListGroup>
                                <ListGroup.Item variant='success'>
                                    <div className='category-delete-text'>
                                        { totalDisplay + ' -- ' + name}
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                    {expenseIds.length > 0 && altCategories.length > 0 && 
                        <>
                            <Row className='mt-5 align-items-center'>
                                <Col xs={9}>
                                    The expenses in the category will be
                                    <span className={deleteAllExpenses ? 'text-danger' : 'text-primary'}>
                                        {deleteAllExpenses ? ' deleted.' : ' moved.'}
                                    </span>
                                </Col>
                                <Col xs={3}>
                                    <Form.Check 
                                        inline
                                        type="switch"
                                        id="delete-expenses-switch"
                                        checked={!deleteAllExpenses}
                                        onChange={() => setDeleteAllExpenses(!deleteAllExpenses)}
                                    />
                                </Col>
                            </Row>
                            {!deleteAllExpenses &&
                                <Row className='mt-3'>
                                    <Col className='text-center'>
                                        <Dropdown onSelect={(id) => setMoveToCategoryId(id)}>
                                            <Dropdown.Toggle variant='outline-primary'>
                                                {moveToCategoryName ? moveToCategoryName : 'Select Category'}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {categoryOptions}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                </Row>
                            }
                        </>
                    }
                    <Row>
                        <Col className='mt-5'>
                            Are you sure you want to perform this action?
                        </Col>
                    </Row>
                    
                </Container>
            </Modal.Body>

            <Modal.Footer className='category-modal-footer'>
                <Button variant='danger' disabled={!deleteAllExpenses && !moveToCategoryId} onClick={handleDeleteCategory}>
                    Delete <FontAwesomeIcon icon={faTrashCan} />
                </Button>
                <Button variant='secondary' onClick={handleCancel}>
                    Cancel <FontAwesomeIcon icon={faXmark} />
                </Button>
            </Modal.Footer>

        </Modal>
    );
}
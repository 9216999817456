import React, { useState } from 'react';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines, faFloppyDisk, faXmark } from '@fortawesome/free-solid-svg-icons';

import './CategoryStyles.css';

export default function CategoryInput({ id, currName, currLimit, isNew, categoryNames, anyCategoryIsNew, updateCategory, setIsEditing, setCategoryOpen, deleteCategory, setShowAlert, setAlertMessage, localeInfo, dragHandleProps }) {
    const [validated, setValidated] = useState(false);
    const [name, setName] = useState(currName ? currName : '');
    const [limitRequired, setLimitRequired] = useState(currLimit || isNew ? true : false);
    const [limit, setLimit] = useState(currLimit);

    function handleSave(event) {
        event.preventDefault();
        event.stopPropagation();

        if (categoryNames.includes(name) && name != currName) {
            const errorMessage = 'This category name is already being used in this budget. All categories must have unique names.';
            event.currentTarget[0].setCustomValidity(errorMessage);
            setAlertMessage(errorMessage);
            setShowAlert(true);
        } else if (limit > 10000000) {
            setAlertMessage('Category limits cannot be set to values larger than 10,000,000');
            setShowAlert(true);
        } else {
            setAlertMessage('');
            setShowAlert(false);
        }

        setValidated(true);
        if (event.currentTarget.checkValidity() === true) {
            updateCategory(id, name, limit);
            setIsEditing(false);
            setCategoryOpen(true);
        } 
    }

    function handleCancel(event) {
        event.stopPropagation();
        if (isNew) {
            deleteCategory(id);
        }
        setIsEditing(false)
    }

    function handleNameChange(e) {
        if (!categoryNames.includes(e.currentTarget.value)) {
            e.currentTarget.setCustomValidity('');
        }
        setName(e.currentTarget.value);
    }

    function handleLimitChange(e) {
        const value = e.currentTarget.value;
        if (isNaN(value)) setLimit('');
        else setLimit(parseFloat(value));
    }

    function handleLimitRequiredChange() {
        setLimitRequired(!limitRequired);
        setLimit(null);
    }

    return (
        <Container onClick={(e) => e.stopPropagation()}>
            <Form noValidate validated={validated} onSubmit={handleSave}>
                <Row className='align-items-center'>
                    <Col xs={1} className='d-none d-sm-flex category-drag-icon' {...dragHandleProps}>
                        <FontAwesomeIcon icon={faGripLines} size='lg' inverse={anyCategoryIsNew} />
                    </Col>
                    <Form.Group as={Col} xs='12' sm='4' className='my-2' controlId="validationCategoryName">
                        <Form.Control 
                            required 
                            size='sm'
                            type="text" 
                            value={name}
                            placeholder="Category Name" 
                            maxLength={255} 
                            onChange={(e) => handleNameChange(e)}
                            isInvalid={name != '' && categoryNames.includes(name) && name != currName}
                        />
                    </Form.Group>

                    <Form.Group as={Col} xs='8' sm='3'className='my-2' controlId="validationCategoryLimit">
                        <InputGroup hasValidation size='sm'>
                            <InputGroup.Text  style={{borderTopRightRadius: '0', borderBottomRightRadius: '0'}}>
                                {localeInfo.currencySymbol}
                            </InputGroup.Text>
                            <Form.Control
                                required={limitRequired}
                                disabled={!limitRequired}
                                type="number"
                                size='sm'
                                min='0'
                                max='10000000'
                                step='0.01'
                                value={limit ? limit : ''}
                                placeholder={limitRequired ? 'Limit' : 'No Limit'}
                                onChange={(e) => handleLimitChange(e)}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Col xs={4} sm={2} xl={1} className='my-2 p-0'>
                        <Form.Check 
                            inline
                            id='limit-required-switch'
                            type='switch'
                            className='m-auto align-self-center'
                            checked={limitRequired}
                            onChange={handleLimitRequiredChange}
                        />
                    </Col>

                    <Col xs={12} sm={2} xl={3} className="text-center my-2 p-1">
                        <ButtonGroup size='sm' className='w-100'>
                            <Button variant='success' className='w-50' type='submit'>
                                <span className='d-inline d-sm-none d-xl-inline'>Save </span>
                                <FontAwesomeIcon icon={faFloppyDisk} />
                            </Button>
                            <Button variant='secondary' className='w-50 text' type='button' onClick={(e) => handleCancel(e)}>
                                <span className='d-inline d-sm-none d-xl-inline'>Cancel </span>
                                <FontAwesomeIcon icon={faXmark} />
                            </Button>
                        </ButtonGroup>
                    </Col>
                    
                </Row>
            </Form>
        </Container>
    );
}
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons';

import './ExpenseStyles.css';

export default function ExpenseDeleteModal({ show, id, description, amountDisplay, deleteExpenses, cancelDeleteExpense }) {
    return (
        <Modal show={show} onHide={cancelDeleteExpense}>

            <Modal.Header closeButton className='expense-modal-header'>
                <Modal.Title>Delete Expense?</Modal.Title>
            </Modal.Header>

            <Modal.Body className='expense-modal-body'>
                <Container>
                    <Row>
                        <Col>You are about to delete:</Col>
                    </Row>
                    <Row>
                        <Col className='my-5 text-center'>
                            <ListGroup>
                                <ListGroup.Item variant='success'>
                                    <div className='expense-delete-text'>
                                        {amountDisplay + ' -- ' + description}
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>Are you sure you want to delete this expense?  This action cannot be undone.</Col>
                    </Row>
                </Container>
            </Modal.Body>

            <Modal.Footer className='expense-modal-footer'>
                <Button variant='secondary' onClick={cancelDeleteExpense}>
                    Cancel <FontAwesomeIcon icon={faXmark} />
                </Button>
                <Button variant='danger' onClick={() => deleteExpenses([id])}>
                    Delete <FontAwesomeIcon icon={faTrashCan} />
                </Button>
            </Modal.Footer>

        </Modal>
    );
}
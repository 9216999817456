import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../Header/Header';
import ListGroup from 'react-bootstrap/ListGroup';
import Progress from '../Progress/Progress';
import SummaryDoughnut from './SummaryDoughnut';
import { currencyFormatter } from '../../utils/currencyFormatter';

import './SummaryStyles.css';

export default function SummaryArea({ categories, expenses, title, max, total, details, localeInfo }) {
    const totalDisplay = currencyFormatter(localeInfo.languageCode, localeInfo.currencyCode).format(total);
    const maxDisplay = currencyFormatter(localeInfo.languageCode, localeInfo.currencyCode).format(max);

    const combinedLimits = categories.length ? categories.reduce((prev, curr) => curr.category_limit ? prev + curr.category_limit : prev + 0, 0) : 0;
    const combinedLimitsDisplay = currencyFormatter(localeInfo.languageCode, localeInfo.currencyCode).format(combinedLimits);
    
    let limitMessage = '';
    if (combinedLimits > max) limitMessage = '(over max)';
    if (combinedLimits == max) limitMessage = '(at max)';

    const biggestExpense = expenses.length ? expenses.reduce((prev, curr) => prev.amount > curr.amount ? prev : curr) : { description: 'None', amount: 0 };
    const biggestExpenseDisplay = currencyFormatter(localeInfo.languageCode, localeInfo.currencyCode).format(biggestExpense.amount);
    
    const smallestExpense = expenses.length ? expenses.reduce((prev, curr) => prev.amount < curr.amount ? prev : curr) : { description: 'None', amount: 0 };
    const smallestExpenseDisplay = currencyFormatter(localeInfo.languageCode, localeInfo.currencyCode).format(smallestExpense.amount);

    return (
        <Container fluid className='summary-area'>
            <Header headerTitle='Summary' />
            <Row>
                <Col>
                    <ListGroup className='summary-list'>
                        <ListGroup.Item className='p-3 summary-title'>BUDGET</ListGroup.Item>
                        <ListGroup.Item><strong>Title:</strong> {title}</ListGroup.Item>
                        <ListGroup.Item><strong>Total:</strong> {totalDisplay} / <strong>Max:</strong> {maxDisplay}</ListGroup.Item>
                        <ListGroup.Item><Progress limit={max} currVal={total} type='budget' /></ListGroup.Item>
                        <ListGroup.Item className='summary-details'><strong>Details:</strong> {details}</ListGroup.Item>
                    </ListGroup>

                    <ListGroup className='summary-list'>
                        <ListGroup.Item className='p-3 mt-3 summary-title'>CATEGORIES</ListGroup.Item>
                        <ListGroup.Item><strong>All Limits:</strong> {combinedLimitsDisplay} {limitMessage}</ListGroup.Item>
                        <ListGroup.Item>
                            <SummaryDoughnut 
                                total={total} 
                                max={max} 
                                categories={categories} 
                                expenses={expenses} 
                                localeInfo={localeInfo} />
                        </ListGroup.Item>
                    </ListGroup>

                    <ListGroup className='summary-list'>

                        <ListGroup.Item className='p-3 mt-3 summary-title'>EXPENSES</ListGroup.Item>
                        <ListGroup.Item><strong>Number: </strong>{expenses.length}</ListGroup.Item>
                        <ListGroup.Item><strong>Total: </strong>{totalDisplay}</ListGroup.Item>
                        <ListGroup.Item><strong>Largest: </strong>{biggestExpenseDisplay} -- {biggestExpense.description}</ListGroup.Item>
                        <ListGroup.Item><strong>Smallest: </strong>{smallestExpenseDisplay} -- {smallestExpense.description}</ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>
            <Row className='text-center mb-3'>
                <Col xs={12} lg={10} className='mx-auto'>
                </Col>
            </Row>
            
        </Container>
    );
}
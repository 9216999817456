export function convertTime(time, type='toDatabase') {
    if (type === 'toDatabase') {
        return time.toISOString().slice(0, 19).replace('T', ' ');
    }
    if (type === 'fromDatabase') {
        const t = time.split(/[- :]/);
        return new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
    }
    console.error('Time converstion failed - conversion type not recognized');
    return null;
}
import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import ExpenseArea from '../Expense/ExpenseArea';
import CategoryDisplay from './CategoryDisplay';
import CategoryInput from './CategoryInput';
import { Draggable } from '@hello-pangea/dnd';

import './CategoryStyles.css';

export default function CategoryRow({ id, position, name, color, limit, is_new, expenses, categoryNames, anyCategoryIsNew, updateCategory, changeCategoryColor, askDeleteCategory, deleteCategory, addNewExpense, updateExpense, askDeleteExpense, deleteExpenses, setShowAlert, setAlertMessage, localeInfo }) {
    const [categoryOpen, setCategoryOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(is_new);
    const [showAddExpenseBtn, setShowAddExpenseBtn] = useState(true);

    function toggleOpenCategory() {
        if (!isEditing) {
            if (categoryOpen) {
                const unsavedExpenses = expenses.filter(expense => expense.is_new).map(exp => exp.id);
                if (unsavedExpenses.length) {
                    deleteExpenses(unsavedExpenses);
                    setShowAddExpenseBtn(false);
                }
            }
            setCategoryOpen(!categoryOpen);
        }
    }

    const categoryRowClasses = 'category category-row' + (categoryOpen ? ' category-row-open' : '') + (isEditing ? ' category-row-editing' : '');

    return (
        <Draggable draggableId={id} index={position} isDragDisabled={anyCategoryIsNew}>
            {provided => (
                <div {...provided.draggableProps} ref={provided.innerRef}>

                    <Row className={categoryRowClasses} onClick={toggleOpenCategory}>
                        {isEditing
                            ? <CategoryInput 
                                id={id}
                                currName={name} 
                                currLimit={limit} 
                                isNew={is_new}
                                categoryNames={categoryNames}
                                anyCategoryIsNew={anyCategoryIsNew}
                
                                setIsEditing={setIsEditing}
                                setCategoryOpen={setCategoryOpen}
                                updateCategory={updateCategory} 
                                deleteCategory={deleteCategory}
                
                                setShowAlert={setShowAlert}
                                setAlertMessage={setAlertMessage}
                
                                localeInfo={localeInfo}
                                dragHandleProps={{...provided.dragHandleProps}}
                            /> 
                            : <CategoryDisplay 
                                id={id}
                                name={name} 
                                color={color}
                                limit={limit} 
                                expenses={expenses}
                                anyCategoryIsNew={anyCategoryIsNew}
                                
                                changeCategoryColor={changeCategoryColor}
                                setIsEditing={setIsEditing}
                                askDeleteCategory={askDeleteCategory}
                    
                                localeInfo={localeInfo}
                                dragHandleProps={{...provided.dragHandleProps}}
                            />
                        }
                    </Row>

                    <ExpenseArea 
                        categoryOpen={categoryOpen}

                        categoryId={id}
                        limit={limit}
                        expenses={expenses} 

                        addNewExpense={addNewExpense}
                        updateExpense={updateExpense}
                        askDeleteExpense={askDeleteExpense}
                        deleteExpenses={deleteExpenses}
                        showAddExpenseBtn={showAddExpenseBtn}
                        setShowAddExpenseBtn={setShowAddExpenseBtn}

                        localeInfo={localeInfo}
                    />
                                
                    {provided.placeholder}

                </div>
            )}
        </Draggable>
    );
}
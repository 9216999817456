import { getRandomColor } from "../utils/getRandomColor";

const example = {
    budget: {
        id: 'example-budget',
        title: 'Monthly Budget Example',
        max: 3500.00,
        details: 'Example budget for demonstration purposes.',
        total: 3485.00,
        created_at: new Date(),
        last_modified: new Date(),
        user_id: 'aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa'
    },
    categories: [
        {
            id: 'd402214a-bb9d-42a3-9e26-7cab8a94c0c3',
            position: 0,
            name: 'Giving',
            category_limit: 100.00,
            budget_id: 'example-budget',
            color: getRandomColor(),
            is_new: false
        },
        {
            id: '5dfb827b-3f3f-4461-b5ff-166917cf32e8',
            position: 1,
            name: 'Saving',
            category_limit: 350.00,
            budget_id: 'example-budget',
            color: getRandomColor(),
            is_new: false
        },
        {
            id: '2b1a7231-0022-46a0-bc6e-1ca49ed7cdf1',
            position: 2,
            name: 'Housing',
            category_limit: 1500.00,
            budget_id: 'example-budget',
            color: getRandomColor(),
            is_new: false
        },
        {
            id: 'ec7ad7e0-b1e9-4433-9ac6-3bfd243c0cd6',
            position: 3,
            name: 'Food',
            category_limit: 500.00,
            budget_id: 'example-budget',
            color: getRandomColor(),
            is_new: false
        },
        {
            id: '29d726f8-b972-492f-9472-571f834984eb',
            position: 4,
            name: 'Utilities',
            category_limit: 200.00,
            budget_id: 'example-budget',
            color: getRandomColor(),
            is_new: false
        },
        {
            id: '4df1b07e-928b-457b-939c-65440118d9e4',
            position: 5,
            name: 'Debt',
            category_limit: 100.00,
            budget_id: 'example-budget',
            color: getRandomColor(),
            is_new: false
        },
        {
            id: 'ae759b86-78b2-4a08-a1da-0c93a26bcbcf',
            position: 6,
            name: 'Medical',
            category_limit: 300.00,
            budget_id: 'example-budget',
            color: getRandomColor(),
            is_new: false
        },
        {
            id: 'f483c242-a09b-433d-a7bd-6f8e893f1131',
            position: 7,
            name: 'Transportation',
            category_limit: 100.00,
            budget_id: 'example-budget',
            color: getRandomColor(),
            is_new: false
        },  
        {
            id: '81998fe8-6598-4d23-8343-8586e9acebb0',
            position: 8,
            name: 'Personal Needs',
            category_limit: 300.00,
            budget_id: 'example-budget',
            color: getRandomColor(),
            is_new: false
        },
        {
            id: 'f59f2a3c-d2a2-4ea9-956e-9f55abc8b057',
            position: 9,
            name: 'Entertainment',
            category_limit: 50.00,
            budget_id: 'example-budget',
            color: getRandomColor(),
            is_new: false
        },
    ],
    expenses: [
        {
            "id": "93b22385-2b46-44fd-b3c7-4ecc9a2fc72a",
            "position": 0,
            "description": "Charity Organization",
            "amount": 30,
            "category_id": "d402214a-bb9d-42a3-9e26-7cab8a94c0c3",
            "is_new": false
        },
        {
            "id": "dcee938f-0de1-47d7-8612-3fe24c4d362d",
            "position": 1,
            "description": "Help Family Members",
            "amount": 50,
            "category_id": "d402214a-bb9d-42a3-9e26-7cab8a94c0c3",
            "is_new": false
        },
        {
            "id": "3056c9d0-2eae-4ccb-9a18-cd8661848598",
            "position": 2,
            "description": "Local Food Bank",
            "amount": 20,
            "category_id": "d402214a-bb9d-42a3-9e26-7cab8a94c0c3",
            "is_new": false
        },
        {
            "id": "05651d82-6953-490c-993e-d1571fced36d",
            "position": 0,
            "description": "Emergency Fund",
            "amount": 100,
            "category_id": "5dfb827b-3f3f-4461-b5ff-166917cf32e8",
            "is_new": false
        },
        {
            "id": "efd12177-8874-468b-bf4b-24d3a002f1cf",
            "position": 1,
            "description": "Kids College Savings",
            "amount": 100,
            "category_id": "5dfb827b-3f3f-4461-b5ff-166917cf32e8",
            "is_new": false
        },
        {
            "id": "28b8776f-4d23-4f5c-b970-76fe2af25618",
            "position": 2,
            "description": "Stock Investments",
            "amount": 50,
            "category_id": "5dfb827b-3f3f-4461-b5ff-166917cf32e8",
            "is_new": false
        },
        {
            "id": "46dfdd66-6080-4863-b75d-0d11aa80c130",
            "position": 0,
            "description": "Mortgage",
            "amount": 1000,
            "category_id": "2b1a7231-0022-46a0-bc6e-1ca49ed7cdf1",
            "is_new": false
        },
        {
            "id": "c4aeda0d-0c23-4a0a-b4c4-4892b5994d64",
            "position": 2,
            "description": "Home Repairs",
            "amount": 100,
            "category_id": "2b1a7231-0022-46a0-bc6e-1ca49ed7cdf1",
            "is_new": false
        },
        {
            "id": "1ed86be5-a29e-4fcd-b66c-fb5c892e2c6f",
            "position": 1,
            "description": "Property Taxes",
            "amount": 400,
            "category_id": "2b1a7231-0022-46a0-bc6e-1ca49ed7cdf1",
            "is_new": false
        },
        {
            "id": "730c82f4-1f2b-4335-919f-305395334b7b",
            "position": 0,
            "description": "Groceries",
            "amount": 400,
            "category_id": "ec7ad7e0-b1e9-4433-9ac6-3bfd243c0cd6",
            "is_new": false
        },
        {
            "id": "182d0f87-1619-41f3-9a67-ca63e175b07f",
            "position": 1,
            "description": "Eating Out",
            "amount": 250,
            "category_id": "ec7ad7e0-b1e9-4433-9ac6-3bfd243c0cd6",
            "is_new": false
        },
        {
            "id": "fe529252-6258-43f9-bef6-f5376afba8e4",
            "position": 0,
            "description": "Electricity",
            "amount": 100,
            "category_id": "29d726f8-b972-492f-9472-571f834984eb",
            "is_new": false
        },
        {
            "id": "6d0f69cf-2704-442f-a2a5-e38906a52842",
            "position": 1,
            "description": "Water/Garbage",
            "amount": 30,
            "category_id": "29d726f8-b972-492f-9472-571f834984eb",
            "is_new": false
        },
        {
            "id": "5de4a9eb-8f59-4c58-9e9f-327ffe2de8c5",
            "position": 2,
            "description": "Phones",
            "amount": 30,
            "category_id": "29d726f8-b972-492f-9472-571f834984eb",
            "is_new": false
        },
        {
            "id": "40a3066c-921f-45fc-97b3-1fad0cf25b7e",
            "position": 3,
            "description": "Internet",
            "amount": 30,
            "category_id": "29d726f8-b972-492f-9472-571f834984eb",
            "is_new": false
        },
        {
            "id": "3735c805-8133-432c-b771-c6c34eb0326a",
            "position": 0,
            "description": "Gas",
            "amount": 60,
            "category_id": "f483c242-a09b-433d-a7bd-6f8e893f1131",
            "is_new": false
        },
        {
            "id": "3cf6f99a-7d6e-4ebc-ac14-7d75ee9d880c",
            "position": 1,
            "description": "Oil Change",
            "amount": 20,
            "category_id": "f483c242-a09b-433d-a7bd-6f8e893f1131",
            "is_new": false
        },
        {
            "id": "227e9df3-fe13-41c0-b371-c8c5c41dd626",
            "position": 0,
            "description": "Insurance",
            "amount": 200,
            "category_id": "ae759b86-78b2-4a08-a1da-0c93a26bcbcf",
            "is_new": false
        },
        {
            "id": "e0085426-24fc-4801-bffd-67b2fc65b1d7",
            "position": 1,
            "description": "Urgent Care Visit",
            "amount": 90,
            "category_id": "ae759b86-78b2-4a08-a1da-0c93a26bcbcf",
            "is_new": false
        },
        {
            "id": "cf778686-0170-4575-a30d-936bc14d0aee",
            "position": 0,
            "description": "Gym Membership",
            "amount": 50,
            "category_id": "81998fe8-6598-4d23-8343-8586e9acebb0",
            "is_new": false
        },
        {
            "id": "6f22a176-ad73-4508-8b58-0980f28d9795",
            "position": 1,
            "description": "Cleaning Supplies",
            "amount": 30,
            "category_id": "81998fe8-6598-4d23-8343-8586e9acebb0",
            "is_new": false
        },
        {
            "id": "3fb4090a-dfd1-443b-8c2a-34b4d6c5ff8e",
            "position": 2,
            "description": "Tools",
            "amount": 40,
            "category_id": "81998fe8-6598-4d23-8343-8586e9acebb0",
            "is_new": false
        },
        {
            "id": "307d0f68-e60d-4602-ad8a-27217d63c61f",
            "position": 3,
            "description": "Babysitter",
            "amount": 80,
            "category_id": "81998fe8-6598-4d23-8343-8586e9acebb0",
            "is_new": false
        },
        {
            "id": "8199d8fe-51fa-43ce-8836-87366485cfb6",
            "position": 4,
            "description": "Haircut",
            "amount": 25,
            "category_id": "81998fe8-6598-4d23-8343-8586e9acebb0",
            "is_new": false
        },
        {
            "id": "93adadd4-ee5d-45c2-a890-63cf74862c71",
            "position": 0,
            "description": "Credit Card",
            "amount": 50,
            "category_id": "4df1b07e-928b-457b-939c-65440118d9e4",
            "is_new": false
        },
        {
            "id": "622a87eb-efc2-4432-9159-3c170b1723c0",
            "position": 1,
            "description": "College Loans",
            "amount": 50,
            "category_id": "4df1b07e-928b-457b-939c-65440118d9e4",
            "is_new": false
        },
        {
            "id": "a4ad5c3e-a2c1-4713-a21a-c31fd8c46060",
            "position": 0,
            "description": "Streaming Subscriptions",
            "amount": 25,
            "category_id": "f59f2a3c-d2a2-4ea9-956e-9f55abc8b057",
            "is_new": false
        },
        {
            "id": "390311b5-a043-4f3b-adbb-43caa6e8aaba",
            "position": 1,
            "description": "Date Night",
            "amount": 75,
            "category_id": "f59f2a3c-d2a2-4ea9-956e-9f55abc8b057",
            "is_new": false
        }
    ]
}

export {example};
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom'; 
import Image from 'react-bootstrap/Image';
import SummaryChartImg from '../assets/imgs/summary-chart-cropped.png';
import CategoriesOpenImg from '../assets/imgs/categories-open-cropped.png';
import MoveCategoryImg from '../assets/imgs/move-category-cropped.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar, faChartPie, faMoneyBillTrendUp } from '@fortawesome/free-solid-svg-icons';

import './PageStyles.css';

export default function Landing() {
    
    return (
        <Container fluid className='landing-container'>

            <Row className='landing-top'>                
                <Col xs={12} md={6} xl={4} className='h-50 mx-auto' >
                    <Row className='h-25'>
                        <Col className='d-flex align-items-center'>
                            <h1>Budgeting should be simple.</h1>
                        </Col>
                    </Row>
                    <Row className='h-50'>
                        <Col className='d-flex align-items-center'>
                            <h4 style={{lineHeight: '1.5'}}>A tool to help you take control of your finances.</h4>
                        </Col>
                    </Row>
                    <Row className='h-25'>
                        <Col className='d-flex align-items-center justify-content-center'>
                            <Link to='/login' className='btn btn-primary'>Try Simply Budget Today!</Link>
                        </Col>
                    </Row>
                </Col>

                <Col xs={12} md={6} xl={4} className='h-50 mx-auto'>
                    <Image src={SummaryChartImg} className='landing-img landing-img-top' />
                </Col>
            </Row>
 
            <Row className='landing-why'>
                <Col className='h-100 text-center'>
                    <Row className='h-25 align-items-center'>
                        <Col>
                            <h2>Why use Simply Budget?</h2>
                        </Col>
                    </Row>

                    <Row className='h-50 align-items-center'>
                        <Col xs={12} sm={10} md={8} className='mx-auto'>
                            <h4 style={{lineHeight: '1.5'}}>Budgeting doesn&apos;t have to be a nightmare. With Simply Budget, you don&apos;t need endless forms and connected accounts.  Just sign up and start managing your money the way you want.</h4>
                        </Col>
                    </Row>
                        
                    <Row className='h-25 d-flex align-items-center justify-content-center'>
                        <Col className='text-center landing-icon'>
                            <FontAwesomeIcon icon={faFileInvoiceDollar} />
                        </Col>
                        <Col className='text-center landing-icon'>
                            <FontAwesomeIcon icon={faChartPie} />
                        </Col>
                        <Col className='text-center landing-icon'>
                            <FontAwesomeIcon icon={faMoneyBillTrendUp} />
                        </Col>
                    </Row>
                </Col>                
            </Row>

            <Row className='landing-slides'>
                <Col xs={10} sm={6} xl={4} className='h-50 mx-auto'>
                    <Image src={CategoriesOpenImg} className='landing-img landing-img-slides' />
                </Col>
                <Col xs={10} sm={6} xl={4} className='h-50 mx-auto'>
                    <Image src={MoveCategoryImg} className='landing-img landing-img-slides' />
                </Col> 
            </Row>

            <Row className='landing-footer'>
                <Col className='text-center'>
                    <Link to='/login' className='btn btn-primary'>Try Simply Budget Today!</Link>
                </Col>
            </Row>

            
        </Container>
    );
}
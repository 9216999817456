import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { currencyFormatter } from '../../utils/currencyFormatter';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function SummaryDoughnut({ total, max, categories, expenses, localeInfo }) {

	const categoryData = categories.map(category => ({
		position: category.position,
		name: category.name,
		backgroundColor: category.color.substring(0, category.color.length-2) + '0.3)',
		borderColor: category.color,
		total: expenses.length ? expenses.filter(expense => expense.category_id === category.id).reduce((a, b) => a + b.amount, 0) : 0
	}))
	.sort((a, b) => a.position - b.position);

	const categoryLabels = [];
	const categoryBackgrounds = [];
	const categoryBorders = [];
	const categoryTotals = [];
	const categoryTooltips = [];

	categoryData.forEach(category => {
		if (category.name.length > 15) {
			categoryLabels.push(category.name.substring(0, 15) + '...');
		} else {
			categoryLabels.push(category.name);
		}
		categoryBackgrounds.push(category.backgroundColor);
		categoryBorders.push(category.borderColor);
		categoryTotals.push(category.total);
		categoryTooltips.push([
			currencyFormatter(localeInfo.languageCode, localeInfo.currencyCode).format(category.total),
			`${Math.round((category.total / total) * 100)}% of total`,
			`${Math.round((category.total / max) * 100)}% of max`
		]);
	});

	const data = {
		labels: categoryLabels,
		datasets: [
			{
				data: categoryTotals,
				backgroundColor: categoryBackgrounds,
				borderColor: categoryBorders,
				borderWidth: 1,
			},
		],
	};

	const options = {
		plugins: {
			tooltip: {
				callbacks: {
					title: (context) => context[0].label,
					label: (context) => categoryTooltips[context.dataIndex]
				}
			}
		}
	}

	return (
		<Doughnut data={data} options={options} />
	);
}

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons';

import './BudgetStyles.css';

export default function BudgetDeleteModal({ show, id, title, cancelDeleteBudget, deleteBudget }) {
    return (
        <Modal show={show} onHide={cancelDeleteBudget}>

            <Modal.Header closeButton className='budget-delete-modal-header'>
                <Modal.Title>Delete Budget?</Modal.Title>
            </Modal.Header>

            <Modal.Body className='budget-delete-modal-body'>
                <Container>
                    <Row>
                        <Col>You are about to delete budget:</Col>
                    </Row>
                    <Row>
                        <Col className='my-5 text-center'>
                        <ListGroup>
                                <ListGroup.Item variant='success'>
                                    <h3 className='budget-delete-text'>{title}</h3>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Are you sure you want to delete this budget?  This action cannot be undone.</p>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>

            <Modal.Footer className='budget-delete-modal-footer'>
                <Button variant='secondary' className='w-25' onClick={cancelDeleteBudget}>
                    Cancel <FontAwesomeIcon icon={faXmark} />
                </Button>
                <Button variant='danger' className='w-25' onClick={() => deleteBudget(id)}>
                    Delete <FontAwesomeIcon icon={faTrashCan} />
                </Button>
            </Modal.Footer>

        </Modal>
    );
}
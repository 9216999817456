import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/esm/Button';
import Progress from '../Progress/Progress';
import { Link } from 'react-router-dom'
import { currencyFormatter } from '../../utils/currencyFormatter';

import './BudgetStyles.css';

export default function BudgetCard({ id, title, max, details, total, setSelectedBudget, setShowBudgetCreateModal, askDeleteBudget, localeInfo }) {
    const [isMouseOver, setIsMouseOver] = useState(false);
    const maxDisplay = currencyFormatter(localeInfo.languageCode, localeInfo.currencyCode, 0).format(max);
    const currTotalDisplay = currencyFormatter(localeInfo.languageCode, localeInfo.currencyCode, 0).format(total);

    function handleEditBudget() {
        setSelectedBudget({
            id: id,
            title: title,
            max: max,
            details: details
        });
        setShowBudgetCreateModal(true);
    }

    return (
        <Card 
            className='m-4 budget-card'  
            onMouseEnter={() => setIsMouseOver(true)} 
            onMouseLeave={() => setIsMouseOver(false)}
        >
            <Card.Header title={title} className='budget-card-header'>
                <Card.Title className='budget-card-title'>
                    {title}
                </Card.Title>
            </Card.Header>

            <Card.Body className='budget-card-body'>
                <Link to={`/budgets/${id}`} style={{textDecoration: "none", color: '#000'}}>
                    <Container>
                        <Row className='mt-1'>
                            <Col className='text-start'>
                                <Card.Text className="text-decoration-none">
                                    <strong>Total: </strong>{currTotalDisplay} 
                                </Card.Text>
                            </Col>
                            <Col className='text-end'>
                                <Card.Text>
                                    <strong>Max: </strong>{maxDisplay}
                                </Card.Text>
                            </Col>
                        </Row>

                        <Row className='my-3'>
                            <Col>
                                <Progress limit={max} currVal={total} type='budget' className='my-4' />
                            </Col>
                        </Row>

                        {details &&
                            <Row className='mt-1'>
                                <Col>
                                    <Card.Text className='budget-card-details' title={details}>
                                        {details}
                                    </Card.Text>
                                </Col>
                            </Row>
                        }
                    </Container>
                </Link>
            </Card.Body>

            <Card.Footer className={isMouseOver ? 'd-flex justify-content-between' : 'd-none'}>
                <Button 
                    variant='outline-danger' 
                    className='budget-card-btn' 
                    disabled={id === 'example-budget'}
                    onClick={() => askDeleteBudget(id, title, max, details)}
                    >Delete
                </Button>
                <Button 
                    variant='outline-primary' 
                    className='budget-card-btn' 
                    disabled={id === 'example-budget'}
                    onClick={handleEditBudget}
                    >Edit
                </Button>
                <Link to={`/budgets/${id}`} className='budget-card-btn'>
                    <Button variant='outline-success' className='w-100 px-0'>Open</Button>
                </Link>
                
            </Card.Footer>
        </Card>
    );
}
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import AlertMessage from '../AlertMessage/AlertMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faFloppyDisk, faPlus } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';

import './BudgetStyles.css';

export default function BudgetCreateModal({ show, id, title, max, details, allOtherBudgets, setShowBudgetCreateModal, addNewBudget, copyBudget, updateBudget, localeInfo }) {
    const isEditMode = id && title && max ? true : false;

    const [isCopyMode, setIsCopyMode] = useState(false);
    const [copySelection, setCopySelection] = useState(null);
    const allOtherBudgetTitles = allOtherBudgets.map(budget => budget.title);    

    const budgetId = id ? id : uuidv4();
    const [budgetTitle, setBudgetTitle] = useState(title ? title : '');
    const [budgetMax, setBudgetMax] = useState(max ? max : '');
    const [budgetDetails, setBudgetDetails] = useState(details ? details : '');

    const [validated, setValidated] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    function handleSaveBudget(event) {
        event.preventDefault();

        if (allOtherBudgetTitles.includes(budgetTitle)) {
            const errorMessage = 'This budget title has already been used.';
            event.currentTarget[2].setCustomValidity(errorMessage);
            setAlertMessage(errorMessage);
            setShowAlert(true);
        }

        if (budgetMax > 9999999999) {
            const errorMessage = 'The budget max number must be less than 9,999,999,999';
            event.currentTarget[2].setCustomValidity(errorMessage);
            setAlertMessage(errorMessage);
            setShowAlert(true);
        }

        setValidated(true);
        if (event.currentTarget.checkValidity() === true) {
            if (isEditMode) {
                updateBudget(budgetId, budgetTitle, budgetMax, budgetDetails);
            } else if (isCopyMode) {
                addNewBudget(budgetId, budgetTitle, budgetMax, budgetDetails);
                copyBudget(copySelection.id, budgetId);
            } else {
                addNewBudget(budgetId, budgetTitle, budgetMax, budgetDetails);
            }
            setShowBudgetCreateModal(false);
        } 
    }

    function handleTitleChange(e) {
        if (!allOtherBudgetTitles.includes(e.currentTarget.value)) {
            e.currentTarget.setCustomValidity('');
        }
        setBudgetTitle(e.currentTarget.value);
    }

    function handleMaxChange(e) {
        if (e.currentTarget.value > 0) {
            e.currentTarget.setCustomValidity('');
        } 
        setBudgetMax(e.currentTarget.value);
    }

    function handleDetailsChange(e) {
        if (e.currentTarget.value.length < 256) {
            e.currentTarget.setCustomValidity('');
        }
        setBudgetDetails(e.currentTarget.value);
    }
    
    function handleResetBudgetModal() {
        setBudgetDetails('');
        setBudgetTitle('');
        setBudgetMax('');
        setShowBudgetCreateModal(false);
    }

    const budgetOptions = allOtherBudgets.map(budget => (
        <Dropdown.Item 
            key={budget.id} 
            eventKey={budget.id} 
            onClick={() => {
                setBudgetTitle('Copy of ' + budget.title);
                setBudgetMax(budget.max);
                setBudgetDetails(budget.details);
            }}
            >{budget.title}
        </Dropdown.Item>
    ));

    return (
        <Modal show={show} onHide={handleResetBudgetModal}>
            <Container fluid className='p-0'>
                <Form
                    noValidate 
                    validated={validated} 
                    onSubmit={handleSaveBudget}
                    className='h-100'
                >
                    <Modal.Header closeButton className='budget-create-modal-header'>
                        <Modal.Title>{isEditMode ? 'Edit Budget' : 'Create New Budget'}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className='budget-create-modal-body'>
                        <Row className='my-3 align-items-center justify-content-center'>
                            <Col xs={9} sm={8}>
                                Start a budget from
                                <span className={isCopyMode ? 'text-success' : 'text-primary'}>
                                    {isCopyMode ? ' a copy.' : ' scratch.'}
                                </span>
                            </Col>
                            <Col xs={3} sm={2}>
                                <Form.Check 
                                    inline
                                    type="switch"
                                    id="delete-expenses-switch"
                                    checked={isCopyMode}
                                    disabled={!allOtherBudgets.length}
                                    onChange={() => {
                                        setIsCopyMode(!isCopyMode);
                                        setCopySelection(null);
                                        setBudgetTitle('');
                                        setBudgetMax('');
                                        setBudgetDetails('');
                                    }}
                                />
                            </Col>
                        </Row>
                        {isCopyMode &&
                            <Row className='my-3 align-items-center justify-content-center'>
                                <Col xs={12} sm={10} className='m-auto'>
                                    <Dropdown className='w-100' onSelect={(id) => setCopySelection(allOtherBudgets.find(budget => budget.id === id))}>
                                        <Dropdown.Toggle className='w-100' variant='outline-primary'>
                                            {copySelection ? copySelection.title : 'Select A Budget'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {budgetOptions}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                        }
                        <Row className='my-3'>
                            <Col xs={12} sm={10} className='m-auto'>
                                <Form.Label>Title *</Form.Label>
                                <Form.Control
                                    required
                                    autoFocus
                                    type='text'
                                    value={budgetTitle}
                                    placeholder='Budget Title'
                                    maxLength={255}
                                    onChange={(e) => handleTitleChange(e)}
                                />
                            </Col>
                        </Row>
                        <Row className='my-3'>
                            <Col xs={12} sm={10} className='m-auto'>
                                <Form.Label>Max *</Form.Label>
                                <InputGroup hasValidation size='sm'>
                                    <InputGroup.Text  style={{borderTopRightRadius: '0', borderBottomRightRadius: '0'}}>
                                        {localeInfo.currencySymbol}
                                    </InputGroup.Text>
                                    <Form.Control
                                        required
                                        type="number"
                                        size='sm'
                                        min='0'
                                        max='9999999999'
                                        step='.01'
                                        value={budgetMax}
                                        placeholder='Budget Max'
                                        onKeyDown={(e) => e.code === 'Minus' ? e.preventDefault() : null}
                                        onChange={(e) => handleMaxChange(e)}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className='my-3'>
                            <Col xs={12} sm={10} className='m-auto'>
                                <Form.Label>
                                    Details <Form.Text muted>{'(optional)'}</Form.Text>
                                </Form.Label>
                                <Form.Control
                                    type='text'
                                    as='textarea'
                                    rows={3}
                                    value={budgetDetails}
                                    placeholder='Budget Details'
                                    maxLength={255}
                                    onChange={(e) => handleDetailsChange(e)}
                                />
                            </Col>
                        </Row>
                        <Row className='my-3'>
                            <Col xs={12} sm={10} className='m-auto'>
                                {isCopyMode
                                    ? 'A brand new budget will be created without any categories or expenses.'
                                    : 'A new budget will be copied with all the same categories and expenses.'
                                }
                            </Col>
                        </Row>
                        <Row>
                            <AlertMessage 
                                show={showAlert}
                                setShow={setShowAlert}
                                variant={'danger'}
                                message={alertMessage}
                                />
                        </Row>
                    </Modal.Body>

                    <Modal.Footer className='budget-create-modal-footer'>
                        <Button variant='secondary' className='w-25' onClick={handleResetBudgetModal}>
                            Cancel <FontAwesomeIcon icon={faXmark} />
                        </Button>
                        <Button type='submit' variant={isEditMode ? 'primary' : 'success'} className='w-25' disabled={!budgetTitle || !budgetMax || (isCopyMode && !copySelection)}>
                            {isEditMode ? 'Save ' : 'Create '}
                            <FontAwesomeIcon icon={isEditMode ? faFloppyDisk : faPlus} />
                        </Button>
                    </Modal.Footer>
                </Form>
            </Container>
        </Modal>
    );
}
import React, { useState, useEffect } from 'react';
import NavbarMain from './components/Navbar/NavbarMain';
import Landing from './pages/Landing';
import Budgets from './pages/Budgets';
import Settings from './pages/Settings';
import Account from './pages/Account';
import Login from './pages/Login';
import BudgetArea from './components/Budget/BudgetArea.js';
import Loader from './components/Loader/Loader';
import axios from 'axios';
import { updateTheme } from './utils/updateTheme.js';
// import { getLocaleInfo } from './utils/getLocaleInfo';
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";

import './assets/global.css';

function App() {
	const [userData, setUserData] = useState({ localeInfo: { languageCode: 'en-us', currencyCode: 'USD', currencySymbol: '$' } });
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [showLoader, setShowLoader] = useState(false);

	const axiosInstance = axios.create({
		baseURL: 'https://simply-budget-app.herokuapp.com/',
		headers: {
			common: { "Content-Type": "application/json" }
		},
		timeout: 5000
	});

	axiosInstance.interceptors.response.use(
		(response) => {
			setTimeout(() => {
				setShowLoader(false);
			}, 100);
			if (response && response.status === 401 && response.data.message != 'LOGIN FAILURE') {
				handleLogout();
			}
			return response;
		}, 
		
		(err) => {
			setTimeout(() => {
				setShowLoader(false);
			}, 100);
			if (err && err.response.status === 401) {
				handleLogout();
			}
			return Promise.reject(err);
		}
	);

	axiosInstance.interceptors.request.use((req) => {
		setShowLoader(true);
		return req;
	});

	async function verifyUser(uid) {
        try {
            const response = await axiosInstance.get(`users/verify/${uid}`);
            if (response && response.status === 200) {
                setIsAuthenticated(true);
				setUserData({
					...userData,
					id: response.data.userData.id,
					displayName: response.data.userData.display_name,
					theme: response.data.userData.theme,
					useExample: response.data.userData.use_example
				});
				localStorage.setItem('display', JSON.stringify(response.data.userData.display_name));
                localStorage.setItem('theme', JSON.stringify(response.data.userData.theme));
                localStorage.setItem('example', JSON.stringify(response.data.userData.use_example ? true : false));
				updateTheme(response.data.userData.theme);
            }
        } catch (err) {
            console.error(err);
            handleLogout();
        }
    }

	function handleLogout() {
		setIsAuthenticated(false);
		localStorage.removeItem('jwt');
		localStorage.removeItem('uid');
		localStorage.removeItem('display');
		localStorage.removeItem('theme');
		localStorage.removeItem('example');
		delete axiosInstance.defaults.headers.common['authorization'];
		delete axiosInstance.defaults.headers.common['uid'];
		delete axiosInstance.defaults.headers.common['display'];
		delete axiosInstance.defaults.headers.common['Content-Type'];

		delete axios.defaults.headers.common['authorization'];
		delete axios.defaults.headers.common['uid'];
		delete axios.defaults.headers.common['display'];
		delete axios.defaults.headers.common['Content-Type'];
	}

	async function getUserData() {
		const jwt = JSON.parse(localStorage.getItem('jwt'));
		const uid = JSON.parse(localStorage.getItem('uid'));

		// Function below relies on geoplugin.net, which requires cost/plan to use https
		// Leaving for possible future consideration, but not needed for now
		// const localeInfo = await getLocaleInfo(axiosInstance, jwt, uid);
		// setUserData({ localeInfo: localeInfo });

		if (jwt && uid) {
			axiosInstance.defaults.headers.common['authorization'] = `Bearer ${jwt}`;
			axiosInstance.defaults.headers.common['uid'] = uid;
			axios.defaults.headers.common['authorization'] = `Bearer ${jwt}`;
			axios.defaults.headers.common['uid'] = uid;
			verifyUser(uid);
		} else {
			setUserData({
				...userData,
				theme: 'light'
			});
			updateTheme('light');
		}
	}

	// When the app loads, get the local storage jwt info and try to verify user
	useEffect(() => {
		getUserData();
	}, []);

	const router = createBrowserRouter([
		{
			path: "/",
			element: <NavbarMain userData={userData} isAuthenticated={isAuthenticated} handleLogout={handleLogout} />,
			errorElement: null,
			children: [
				{
					path: "/home",
					element: <Landing />,
				},
				{
					path: "/login",
					element: isAuthenticated ? <Navigate replace to='/budgets' /> : <Login userData={userData} setUserData={setUserData} setIsAuthenticated={setIsAuthenticated} axiosInstance={axiosInstance} handleLogout={handleLogout} />,
				},
				{
					path: "/budgets",
					element: isAuthenticated ? <Budgets userData={userData} axiosInstance={axiosInstance} /> : <Navigate replace to='/login' />
				},
				{
					path: "/budgets/:id",
					element: isAuthenticated ? <BudgetArea userData={userData} axiosInstance={axiosInstance} /> : <Navigate replace to='/login' />
				},
				{
					path: "/settings",
					element: isAuthenticated ? <Settings userData={userData} setUserData={setUserData} axiosInstance={axiosInstance} /> : <Navigate replace to='/login' />
				},
				{
					path: "/account",
					element: isAuthenticated ? <Account userData={userData} /> : <Navigate replace to='/login' />
				},
				{
					path: "/",
					element: <Navigate replace to='/home' />
				},
				{
					path: "/*",
					element: <Navigate replace to='/home' />
				}
			]
		}
	]);

	return (
		<div className="App">
			<RouterProvider router={router} />
			{showLoader && <Loader showLoader={showLoader} />}
		</div>
	);
}

export default App;

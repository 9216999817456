import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Header({ headerTitle, children }) {
    return (
        <Row className='m-4 text-center'>
            <Col className='m-auto my-2 p-0'>
                <h2>{headerTitle}</h2>
            </Col>
            {children}
        </Row>
    );
}
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShuffle } from '@fortawesome/free-solid-svg-icons';


import './CategoryStyles.css';

export default function CategoriesHeader() {
    return (
        <Row className='category categories-header'>
            <Col xs={2} sm={1}>
                <h3><FontAwesomeIcon icon={faShuffle} /></h3>
            </Col>

            <Col xs={6} sm={4} md={3}>
                <h3>Name</h3>
            </Col>

            <Col xs={4} md={3}>
                <h3>Amount</h3>
            </Col>

            <Col xs={3} className='d-none d-md-inline-block'>
                <h3>Limit</h3>
            </Col>

            <Col xs={4} sm={3} md={2} className='d-none d-sm-inline-block mr-4'>
                <h3>Action</h3>
            </Col>
            
        </Row>
    );
}

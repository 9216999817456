import React from 'react';
import NavbarArea from './NavbarArea';
import { Outlet } from 'react-router-dom';

export default function NavbarMain({ userData, isAuthenticated, handleLogout }) {
    return (
        <>
            <NavbarArea userData={userData} isAuthenticated={isAuthenticated} handleLogout={handleLogout} />
            <Outlet />
        </>
    );
}
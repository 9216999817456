export function updateTheme(theme='light') {
    const rootStyle = document.documentElement.style;
    const opacityVal = '0.4'
    const palettes = {
        'calm': ['rgba(134, 176, 73, 1)', 'rgba(223, 245, 206, 1)', 'rgba(82, 91, 136, 1)', 'rgba(222, 226, 252, 1)', 'rgba(0, 0, 0, 1)', 'rgba(255, 255, 255, 1)'],
        'light': ['rgba(1, 152, 117, 1)', 'rgba(89, 110, 92, 1)', 'rgba(200, 184, 138, 1)', 'rgba(178, 171, 153, 1)', 'rgba(1, 1, 1, 1)', 'rgba(255, 255, 255, 1)'],
        'dark': ['rgba(6, 35, 21, 1)', 'rgba(6, 55, 58, 1)', 'rgba(31, 95, 91, 1)', 'rgba(21, 153, 71, 1)', 'rgba(255, 255, 255, 1)', 'rgba(0, 0, 0, 1)'],
    }

    rootStyle.setProperty('--primary1', palettes[theme][0]);
    rootStyle.setProperty('--primary1-transparent', palettes[theme][0].slice(0, -2) + opacityVal);

    rootStyle.setProperty('--primary2', palettes[theme][1]);
    rootStyle.setProperty('--primary2-transparent', palettes[theme][1].slice(0, -2) + opacityVal);

    rootStyle.setProperty('--secondary1', palettes[theme][2]);
    rootStyle.setProperty('--secondary1-transparent', palettes[theme][2].slice(0, -2) + opacityVal);

    rootStyle.setProperty('--secondary2', palettes[theme][3]);
    rootStyle.setProperty('--secondary2-transparent', palettes[theme][3].slice(0, -2) + opacityVal);

    rootStyle.setProperty('--text-primary', palettes[theme][4]);
    rootStyle.setProperty('--text-secondary', palettes[theme][5]);
}
import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faChartPie, faUser, faGear, faRightFromBracket, faRightToBracket } from '@fortawesome/free-solid-svg-icons';

import './NavbarStyles.css';

export default function NavbarArea({ userData, isAuthenticated, handleLogout }) {
    
    function getLinkClass(isActive) {
        if (isActive) {
            return 'navbar-link navbar-link-active'
        }
        return 'navbar-link';
    }

    return (
        <Navbar expand={'md'} className="navbar">
            <Container fluid className='h-100'>
                <Navbar.Brand>
                    <NavLink to='/home' className='navbar-brand'>Simply Budget</NavLink>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls='responsive-navbar' />

                <Navbar.Collapse id='responsive-navbar' className="justify-content-end">
                    <Nav className="align-items-center">

                        <div className='navbar-link-container'>
                            <NavLink to='/home' className={({isActive}) => getLinkClass(isActive)}>
                                <FontAwesomeIcon icon={faHouse} /> Home
                            </NavLink>
                        </div>

                        {isAuthenticated &&
                            <div className='navbar-link-container'>
                                <NavLink to='/budgets' className={({isActive}) => getLinkClass(isActive)}>
                                    <FontAwesomeIcon icon={faChartPie} /> Budgets
                                </NavLink>
                            </div>
                        }

                        {isAuthenticated &&
                            <div className='d-sm-none navbar-link-container'>
                                <NavLink to='/settings' className={({isActive}) => getLinkClass(isActive)}>
                                <FontAwesomeIcon icon={faGear} /> Settings
                                </NavLink>
                            </div>
                        }

                        {isAuthenticated &&
                            <div className='d-sm-none navbar-dropdown-container'>
                                <NavLink to='/login' className={({isActive}) => getLinkClass(isActive)} onClick={handleLogout}>
                                    <FontAwesomeIcon icon={faRightFromBracket} /> Logout                                           
                                </NavLink>
                            </div>
                        }
                        
                        {isAuthenticated &&
                            <div className='d-none d-sm-block navbar-link-container'>
                                <NavDropdown 
                                    align='end' 
                                    title={<span><FontAwesomeIcon icon={faUser} /> Account</span>} 
                                    className="navbar-link"
                                >

                                    <div className='navbar-dropdown-container navbar-display-name'>
                                        <strong>{userData.displayName}</strong>
                                    </div>
                                    
                                    <NavDropdown.Divider />

                                    <div className='navbar-dropdown-container'>
                                        <NavLink to='/settings' className={({isActive}) => getLinkClass(isActive)}>
                                            <FontAwesomeIcon icon={faGear} /> Settings
                                        </NavLink>
                                    </div>

                                    <div className='navbar-dropdown-container'>
                                        <NavLink to='/login' className={({isActive}) => getLinkClass(isActive)} onClick={handleLogout}>
                                            <FontAwesomeIcon icon={faRightFromBracket} /> Logout                                   
                                        </NavLink>
                                    </div>
                                </NavDropdown>
                            </div>
                        }
                        {!isAuthenticated &&
                            <div className='navbar-link-container'>
                                <NavLink to='login' className={({isActive}) => getLinkClass(isActive)}>
                                <FontAwesomeIcon icon={faRightToBracket} /> Login
                                </NavLink>
                            </div>
                        }
                    </Nav>
                    
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
